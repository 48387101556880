import axios from 'axios';
import { UPDATE_NEW_PASSWORD_URL, GET_PROFILE_URL } from '../../api';
import cogoToast from "cogo-toast";
import jwt_decode from "jwt-decode";
import setAuthToken from "../../utils/setAuthToken";

//Register User
export const updateNewPassword = (userData, code) => {
    return function() {
        return axios.post(UPDATE_NEW_PASSWORD_URL(code), userData).then((response) => {
            return {
                cancel: false,
                data: response.data
            }
        }).catch((err) => {
            const option = {
                hideAfter: 5
            };

            cogoToast.error(err.response.data.error, option);
        });
    }
};


export const getProfile = () => {
    return function(dispatch) {
        return axios.get(GET_PROFILE_URL).then((response) => {

            const { token } = response.data;

            //Set token to localStorage
            localStorage.setItem('jwtToken', token);

            //Set token to Auth header
            setAuthToken(token);

            //Decode token to get user data
            const decoded = jwt_decode(token);

            dispatch({
                type: 'SET_CURRENT_USER',
                payload: decoded
            });

            return {
                cancel: false,
                data: response.data
            }
        }).catch((err) => {
            const option = {
                hideAfter: 5
            };

            cogoToast.error(err.response.data.error, option);
        });
    }
};
