import axios from 'axios';
import { GET_CAMPAIGN_URL, GET_INTEREST_URL } from '../../api';

//Get Currernt Campaign
export const getCurrentCampaign = () => {
    return axios.get(GET_CAMPAIGN_URL).then((response) => {
        return {
            error: false,
            data: response.data
        }
    }).catch(() => {
        return {
            error: true,
            data: null
        }
    });
};

export const getInterest = () => {
    return axios.get(GET_INTEREST_URL).then((response) => {
        return {
            error: false,
            data: response.data
        }
    }).catch(() => {
        return {
            error: true,
            data: null
        }
    });
};

// Clear Campaign
export const clearCurrentCampaign = () => {
    return {
        type: 'CLEAR_CAMPAIGN'
    }
};
