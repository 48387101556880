import React from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import Logo from "../../assets/img/invoice-logo.png";

const TermsConditions = () => {
    document.title = "Terms and Conditions";

    return (
        <div>
            <div className="content bd-b">
                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <div className="d-sm-flex align-items-center">
                        <div className="d-flex">
                            <img src={Logo} alt="logo" className="ht-60 mg-b-6"/>
                            <div className="mg-l-15 mg-t-5">
                                <h4 className="mg-b-5">Terms and Conditions</h4>
                                <p className="mg-b-0 tx-color-03">Read it carefully.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <h5>AdzLok Advertising Agreement</h5>
                    <p className="tx-color-02 mg-b-0">This Advertising Agreement (“Advertising Agreement”) is made between the company and individual accepting this agreement and the terms set forth herein And</p>
                    <p>AdzLok (Proprietary) limited a company incorporated under the Laws of the Republic of South Africa with company registration number: 2015/040534/07</p>
                    <p>All and any services rendered (herein referred to as “the Services”) by AdzLok are so as rendered to the Advertiser hereof on the terms and conditions set under this Advertising Agreement.</p>
                    <p>1. AdzLok undertakes to provide the Advertiser with the following Services under this Advertising Agreement on the AdzLok’s Application Platform;</p>
                    <p>2. 1000 (One Thousand) Impressions or more (“Impressions”) or 1,000 (One Thousand) Clicks or more (“Clicks”) viewed on the advert placed by the Advertiser on the AdzLok Advertising Application Platform.</p>
                    <p>3. For the avoidance of doubt, the parties hereto mutually agree that every time a Phone User (“Phone User”) of the AdzLok Application views the Advertisers advert, it will be counted as an Impression of or Click of the Advertiser’s advert on the AdzLok Application.</p>
                    <p>4. The parties further agree that the adverts will be viewed in 3 (three) ways by the Phone Users;</p>
                    <p>4.1 On the smartphone with full lock screen where all adverts running are placed and this will not include partial screens and a full lock screen as a normal wallpaper will appear on the screen.</p>
                    <p>4.2 Inside the APPs "Home" Tab where all adverts running are also placed.</p>
                    <p>4.3 Under the "Viewed" Tab: Adverts that have been seen by the user are temporarily saved. If the advert has not been consumed it will be saved under this Tab and the Phone User has the option of going back to view the Advert.</p>
                    <p>5. Kindly note that the 3 (three) viewing options listed in clause 4 hereto, will form the basis of an advert being seen and will count as an Impression or Click from every Phone User that views the advert.</p>
                    <p>6. AdzLok will provide the Advertisers with a monthly report 30 (thirty) days after the day that the advert has been placed on the AdzLok Application.</p>
                    <p>7. Any Impressions or Clicks not consumed within a month purchased in, will be carried over to the next monthly cycle. In the unlikely event of the Advertiser having credit in Impressions or Clicks not consumed for the duration of this Advertising Agreement, the parties shall agree to create another campaign to cater for the remainder of the unconsumed Impressions and Clicks. No cash refund will be made available to the Advertisers for any credits in Impressions or Clicks.</p>
                    <p>8. The monthly report provided to the Advertiser by AdzLok will include gender and age groups of the Phone Users. It is agreed and understood by the parties that no personal identifiable information will be shared in the monthly report regarding the Phone Users.</p>
                    <p>9.Duration</p>
                    <p>Adverts are run according to the calendar months as set out by the AdzLok system as below.</p>
                    <p>28 days equals two (1) calendar month</p>
                    <p>84 days equals two (3) calendar months</p>
                    <p>168 days equals six (6) calendar months</p>
                    <p>252 days equals nine (9) calendar months</p>
                    <p>336 days equals twelve (12) calendar months</p>
                    <p>10. The Advertiser is hereby reminded that the following adverts may not be placed for advertising and will constitute a material breach of this Advertising Agreement subject to the termination provisions hereto;</p>
                    <p>10.1 The Advertiser may not post any materials that they do not have permission, right or license to use.</p>
                    <p>10.2 Post objectionable, offensive, unlawful, deceptive or harmful content.</p>
                    <p>10.3 Post personal, private or confidential information belonging to other people.</p>
                    <p>10.4 Request personal information from a minor.</p>
                    <p>10.5 Impersonate or misrepresent your affiliation with another person, or entity.</p>
                    <p>10.6 Post or transmit spam, including but not limited to unsolicited or unauthorized advertising, promotional materials, or informational announcements.</p>
                    <p>10.7 Plan or engage in any illegal, fraudulent, or manipulative activity.</p>
                    <p>11. All fees under this Advertising Agreement are due immediately and payable upfront before the advert is launched. Should the Advertiser default on any payment due to AdzLok, it agrees to pay interest and all legal costs associated which AdzLok may incur.</p>
                    <p>12. The Advertiser indemnifies and therefore exempts AdzLok, it’s officers, employees, agents and affiliates (and their employees and agents) against any action, claim, loss, expense or cost suffered or incurred whether directly or indirectly by AdzLok, it’s officers, employees, agents and affiliates (and their employees and agents) as a result of any act or omission of AdzLok, it’s officers, employees, agents and affiliates (and their employees and agents) or as a result of any breach by AdzLok of any of it’s obligations.</p>
                    <p>13. AdzLok hereby disclaims and shall be exempt from and shall not be liable under any circumstances for any indirect or consequential damages of any nature or any loss of profits, damages or added expense of any nature which the Advertiser may suffer as a result of any act or omission of AdzLok, it’s agents or employees or as a result of any breach by AdzLok of any of its obligations. AdzLok shall not be liable to the Advertiser for;</p>
                    <p>13.1 any errors or omissions in advertisements placed by AdzLok, or for advertisements which are not inserted on the date(s) or at the time(s) specified in the purchased package as indicated in clause 9 hereto.</p>
                    <p>13.2 Any act or omission attributable to any advertising platform, its agents or employees.</p>
                    <p>14. The parties mutually agree that this Advertising Agreement may be terminated by 30 (thirty) days written notice to AdzLok.</p>
                    <p>It is further agreed and understood that, the Advertiser shall be liable for all fees due to AdzLok during the termination period for all services rendered and services being rendered.</p>
                    <p>This Advertising Agreement constitutes the entire agreement between the Advertiser and AdzLok and supersedes any prior agreements with respect to the same subject matter hereto.</p>
                    <p>By accepting the terms and conditions at registration you hereby accept the terms set out in this agreement.</p>
                </div>
            </div>

            <footer className="footer">
                <div>
                    <span>&copy; {moment().format("YYYY")} AdzLok. </span>
                    <span>All Right Reserved.</span>
                </div>
                <div>
                    <nav className="nav">
                        <Link to="/" className="nav-link">Login</Link>
                        <Link to="#" className="nav-link">Contact Us</Link>
                    </nav>
                </div>
            </footer>
        </div>
    );
};

export default TermsConditions;
