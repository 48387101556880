import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {VERIFY_GENERATED_LINK_URL} from '../../api';
import axios from 'axios';
import $ from "jquery";
import feather from "feather-icons";

const VerifyLink = (props) => {
    const [status, _status] = useState("");
    const [message, _message] = useState("");
    const [title, _title] = useState("");
    const [icon, _icon] = useState("");
    const [loading, _loading] = useState(true);

    document.title = "Account Activation Status";

    $(function () {
        feather.replace();
    });

    useEffect(() => {
        const token = props.match.params.token || "unauthorized";
        axios.post(VERIFY_GENERATED_LINK_URL+`/token/`+token).then(res => {
            const {message, title, data, icon} = res.data;
            _status(data);
            _message(message);
            _icon(icon);
            _title(title);
            _loading(false);
        }).catch(() => {
            _loading(false);
        });
    }, []);

    if(loading === true) {
        return (
            <div className="content content-fixed content-auth-alt">
                <div className="container d-flex justify-content-center ht-100p">
                    <div className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <p className="mg-t-10">Verifing</p>
                    </div>
                </div>
            </div>
        );
    } else {
        if(status === "response") {
            return(
                <div className="content content-fixed content-auth-alt">
                    <div className="container ht-100p">
                        <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                            <div className="container ht-100p">
                                <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                                    <div className="wd-80p wd-sm-300 mg-b-15 tx-center"><i className="wd-60 ht-60" data-feather="link"/></div>
                                    <h4 className="tx-20 tx-sm-24">{title}</h4>
                                    <p className="tx-color-03 mg-b-40 tx-center">{message}</p>
                                    <div className="tx-13 tx-lg-14 mg-b-40">
                                        <Link to="/" className="btn btn-brand-02 d-inline-flex align-items-center">Sign In</Link>
                                        <Link to="mailto:support@adzlok.co.za" className="btn btn-white d-inline-flex align-items-center mg-l-5">Contact Support</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return(
                <div className="content content-fixed content-auth-alt">
                    <div className="container ht-100p">
                        <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                            <div className="container ht-100p">
                                <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                                    <div className="wd-80p wd-sm-300 mg-b-15 tx-center"><i className="wd-60 ht-60" data-feather="link"/></div>
                                    <h4 className="tx-20 tx-sm-24 tx-center">This link does not exist</h4>
                                    <p className="tx-color-03 mg-b-40">This link is either does not exist or link has been deleted.</p>
                                    <div className="tx-13 tx-lg-14 mg-b-40 tx-center">
                                        <Link to="/" className="btn btn-brand-02 d-inline-flex align-items-center">Home Page</Link>
                                        <Link to="mailto:support@adzlok.co.za" className="btn btn-white d-inline-flex align-items-center mg-l-5">Contact Support</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
};

export default VerifyLink;
