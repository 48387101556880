import React from 'react';
import Footer from "../layout/Footer";
import {Link} from "react-router-dom";
import $ from "jquery";
import feather from "feather-icons";

const Message = (props) => {
    const {data, title, icon} = props.location.state || "unauthorized";
    document.title = "Message";

    $(function () {
        feather.replace();
    });

    let renderMessage = () => {
        if(data) {
            return(
                <div className="container ht-100p">
                    <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                        <div className="wd-80p wd-sm-300 mg-b-15 tx-center"><i className="wd-60 ht-60" data-feather={icon}/></div>
                        <h4 className="tx-20 tx-sm-24">{title}</h4>
                        <p className="tx-color-03 mg-b-40 tx-center">{data}</p>
                        {/*<p className="tx-color-03 mg-b-40 tx-center">{data} <br/>Please check your email and click the verify button or link to verify your account.</p>*/}
                        <div className="tx-13 tx-lg-14 mg-b-40">
                            <Link to="/" className="btn btn-brand-02 d-inline-flex align-items-center">Sign In</Link>
                            <a href="mailto:support@adzlok.co.za" className="btn btn-white d-inline-flex align-items-center mg-l-5">Contact Support</a>
                        </div>
                    </div>
                </div>
            );
        } else {
            return(
                <div className="container ht-100p">
                    <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                        <div className="wd-80p wd-sm-300 mg-b-15 tx-center"><i className="wd-60 ht-60" data-feather="link"/></div>
                        <h4 className="tx-20 tx-sm-24 tx-center">404 Page</h4>
                        <p className="tx-color-03 mg-b-40">This is not the page you are looking for. Please visit www.adzlok.co.za</p>
                        <div className="tx-13 tx-lg-14 mg-b-40 tx-center">
                            <a href="#" className="btn btn-brand-02 d-inline-flex align-items-center">Home Page</a>
                            <a href="mailto:support@adzlok.co.za" className="btn btn-white d-inline-flex align-items-center mg-l-5">Contact Support</a>
                        </div>
                    </div>
                </div>
            )
        }
    };

    return (
        <div>
            <div className="content content-fixed content-auth-alt">
                <div className="container ht-100p">
                    <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                        {renderMessage()}
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
};

export default Message;
