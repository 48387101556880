import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";

const Footer = () => {
    return (
        <footer className="footer">
            <div>
                <span>&copy; {moment().format("YYYY")} AdzLok. </span>
                <span>All Right Reserved.</span>
            </div>
            <div>
                <nav className="nav">
                    <Link to="/terms-conditions" target="_blank" className="nav-link">Terms And Conditions</Link>
                    <a href="mailto:support@adzlok.co.za" className="nav-link">Contact Us</a>
                </nav>
            </div>
        </footer>
    );
};

export default Footer;
