import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';
import {SIGNUP_URL, SIGNIN_URL, FORGOT_PASSWORD_URL} from '../../api';
import jwt_decode from 'jwt-decode';
import cogoToast from "cogo-toast";

//Register User
export const registerUser = (userData) => {
    return function(dispatch) {
        return axios.post(SIGNUP_URL, userData).then((response) => {
            return {
                error: false,
                data: response.data
            }
        }).catch((err) => {
            const option = {
                hideAfter: 5
            };

            cogoToast.error(err.response.data.error, option);
            return {
                error: true,
                data: null
            }
        });
    }
};

//Login - Auth Token
export const loginUser = (userData) => {
    return function(dispatch) {
        return axios.post(SIGNIN_URL, userData).then((response) => {
            //Save to localStorage
            const { token } = response.data;

            //Set token to localStorage
            localStorage.setItem('jwtToken', token);

            //Set token to Auth header
            setAuthToken(token);

            //Decode token to get user data
            const decoded = jwt_decode(token);

            //Set current user
            dispatch(setCurrentUser(decoded));
        }).catch((err) => {
            const option = {
                hideAfter: 5
            };

            cogoToast.error(err.response.data.email || err.response.data.password, option);
        });
    }
};

export const forgotPassword = (userData) => {
    return function() {
        return axios.post(FORGOT_PASSWORD_URL, userData).then((response) => {
            return {
                cancel: false,
                data: response.data
            }
        }).catch((err) => {
            const option = {
                hideAfter: 5
            };

            cogoToast.error(err.response.data.email, option);
            return {
                cancel: true,
                data: null
            }
        });
    }
};

//Set Logged in User
export const setCurrentUser = (decoded) => {
    return {
        type: 'SET_CURRENT_USER',
        payload: decoded
    }
};

export const logoutUser = () => {
    return function(dispatch) {
        //Remove toke from localStorage
        localStorage.clear();

        //Remove auth header for future requests
        setAuthToken(false);

        //Set current user to {} which will set isAuthenticated to false
        dispatch(setCurrentUser({}))
    }
};
