import axios from "axios";
import {GET_COUNTRY} from "../../api";
import cogoToast from "cogo-toast";

export const getCountry = () => {
    return function(dispatch) {
        return axios.get(GET_COUNTRY).then((response) => {
            dispatch({
                type: 'COUNTRY',
                payload: response.data
            })
        }).catch((err) => {
            const option = {
                hideAfter: 5
            };
            cogoToast.error("Something went wrong.", option);
        });
    }
};
