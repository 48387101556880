import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import campaignReducer from './campaignReducer';
import balanceReducer from './balanceReducer';
import invoiceReducer from './invoiceReducer';
import reportReducer from './reportReducer';
import campaignAdReducer from "./campaignAdReducer";
import adReportReducer from "./adReportReducer";
import regionReducer from "./regionReducer";
import routeReducer from "./routeReducer";
import accountReducer from "./accountReducer";

export default combineReducers({
    auth: authReducer,
    errors: errorReducer,
    campaign: campaignReducer,
    balance: balanceReducer,
    invoice: invoiceReducer,
    report: reportReducer,
    campaign_ad: campaignAdReducer,
    ad_report: adReportReducer,
    region: regionReducer,
    route: routeReducer,
    account: accountReducer
});
