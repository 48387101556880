import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import 'components-jqueryui';
import 'parsleyjs';
import {useSelector} from 'react-redux';
import cogoToast from "cogo-toast";
import 'select2';
import axios from "axios";
import {UPLOAD_CAMPAIGN_URL, GET_REGION_DATA, GET_INTEREST_URL} from "../../api";
import {getInterest} from "../../redux/actions";
import interests from "../../persist/interests";
import {Link, NavLink} from "react-router-dom";
import Header from "../layout/Header";

const CreateAd = () => {

    document.title = "Create Campaign";

    const option = {
        hideAfter: 5
    };

    const token = localStorage.getItem('jwtToken');
    const auth = useSelector((state) => state.auth);

    const region_info = 'Please note that selecting a specific suburb or city means that your ad shall only be placed in this specific targeted area. Choosing the province without specifying the suburb or city means that your ad is shown in every suburb or city within that province that has active AdzLok users on the platform. This option is available for you to target specific areas of interest to you and your business.';

    const [campaign_name, _campaign_name] = useState("");
    const [budget, _budget] = useState("");
    const [fbalance, _fbalance] = useState(0);
    const [sbalance, _sbalance] = useState("");
    const [ads_raw, _ads_raw] = useState([]);
    const [ads, _ads] = useState([]);
    const [ad_name, _ad_name] = useState("");
    const [ad_budget, _ad_budget] = useState("");
    const [ad_category, _ad_category] = useState("");
    const [extra_category, _extra_category] = useState([]);
    const [interest, _interest] = useState([]);
    const [ad_duration, _ad_duration] = useState("");
    const [upload_image, _upload_image] = useState([]);
    const [img_src, _img_src] = useState("");
    const [img_name, _img_name] = useState("");
    const [img_preview, _img_preview] = useState("");
    const [type, _type] = useState("add");
    const [start_date, _start_date] = useState("");
    const [cost_model, _cost_model] = useState("cpm");
    const [start_hour, _start_hour] = useState(0);
    const [end_hour, _end_hour] = useState(23);
    const [total_clicks, _total_clicks] = useState(0);
    const [click_per_day, _click_per_day] = useState(0);
    const [target_url, _target_url] = useState("");
    const [custom_time, _custom_time] = useState("");
    const [disable_hour, _disable_hour] = useState("");
    const [disable_region, _disable_region] = useState("disabled");
    const [disable_ad_category, _disable_ad_category] = useState("disabled");
    const [disable_category, _disable_category] = useState("");
    const [img_disabled, _img_disabled] = useState("");
    const [custom_cost, _custom_cost] = useState("cpm");
    const [regionType, _regionType] = useState("");
    const [regionData, _regionData] = useState("");
    const [uri_boolean, _uri_boolean] = useState("disabled");
    const [age_group_a, _age_group_a] = useState(false);
    const [age_group_b, _age_group_b] = useState(false);
    const [age_group_c, _age_group_c] = useState(false);
    const [age_group_d, _age_group_d] = useState(false);
    const [switch_button, _switch_button] = useState(false);
    const [stateReady, setStateReady] = useState(false);
    const [previous, _previous] = useState("");
    const [img_required, _img_required] = useState("required");
    const [required_category, _required_category] = useState("required");
    const [_index, __index] = useState(0);
    const [region_required, _region_required] = useState("required");
    const [ad_img, _ad_img] = useState("");
    const [gender, _gender] = useState("");
    const [duration, _duration] = useState([
        {key: "1", name: "1"},
        {key: "7", name: "7"},
        {key: "28", name: "28"},
        {key: "84", name: "84"},
        {key: "168", name: "168"},
        {key: "252", name: "252"},
        {key: "336", name: "336"}
    ]);

    $(function () {
        $('[data-toggle="popover"]').popover({
            animation: true
        });

        $('body').on('click', function (e) {
            //did not click a popover toggle or popover
            if ($(e.target).data('toggle') !== 'popover'
                && $(e.target).parents('.popover.in').length === 0) {
                $('[data-toggle="popover"]').popover('hide');
            }
        });

        var app = app || {};

        // Utils
        (function ($, app) {
            'use strict';

            app.utils = {};

            app.utils.formDataSuppoerted = (function () {
                return !!('FormData' in window);
            }());

        }($, app));

        // Parsley validators
        (function ($, app) {
            'use strict';

            window.Parsley
                .addValidator('filemaxmegabytes', {
                    requirementType: 'string',
                    validateString: function (value, requirement, parsleyInstance) {
                        if (!app.utils.formDataSuppoerted) {
                            return true;
                        }

                        var file = parsleyInstance.$element[0].files;
                        var maxBytes = requirement * 1048576;

                        if (file.length === 0) {
                            return true;
                        }

                        return file.length === 1 && file[0].size <= maxBytes;

                    },
                    messages: {
                        en: 'File is to big'
                    }
                })
                .addValidator('filemimetypes', {
                    requirementType: 'string',
                    validateString: function (value, requirement, parsleyInstance) {

                        if (!app.utils.formDataSuppoerted) {
                            return true;
                        }

                        var file = parsleyInstance.$element[0].files;

                        if (file.length === 0) {
                            return true;
                        }

                        var allowedMimeTypes = requirement.replace(/\s/g, "").split(',');
                        return allowedMimeTypes.indexOf(file[0].type) !== -1;

                    },
                    messages: {
                        en: 'Only jpeg, gif and png is allowed'
                    }
                });
        }($, app));
    });

    useEffect(() => {
        getInterest().then((result) => {
            if(result.error !== true) {
                _interest(result.data);
            }
        });

        $(function () {
            $('#date_from').datepicker({
                showOtherMonths: true,
                selectOtherMonths: true,
                minDate: 2,
                defaultDate: '+1w',
                numberOfMonths: 1,
                dateFormat: 'yy/mm/dd'
            });

            $('#_basic').parsley();

            $("#_basic").on('submit', function(e){
                e.preventDefault();
            });

            $('.ad-select2').select2({
                minimumResultsForSearch: Infinity,
                placeholder: 'Select'
            });

            $('#category_data').select2({
                placeholder: "Please select an skill",
                allowClear: true
            });
        });

        return () => {
            $("#date_from").datepicker("destroy");
            $('#_basic').parsley().destroy();
        }
    }, []);

    const handleCampaignNameChange = (event) => {
        _campaign_name(event.target.value);
    };

    const handleBudgetChange = (event) => {
        _budget(event.target.value);
    };

    const handleAdName = (event) => {
        _ad_name(event.target.value);
    };

    const handleAdBudget = (event) => {
        _ad_budget(event.target.value);
        calculateClickRate($('#custom_cost').val(), $('#ad_duration').val(), event.target.value);
    };

    const handleCategorySelected = (event) => {
        _ad_category(event.target.value);

        if(event.target.value !== "") {
            _disable_ad_category("");
            _required_category("required");

            $(".category-select2").empty().select2();

            const _interest = interest.find((result) => result.key === event.target.value);

            if(_interest.child.length === 0) {
                _disable_ad_category("disabled");
                _required_category("")
            }

            $('.category-select2').select2({
                data: _interest.child,
                placeholder: 'Select',
                dropdownParent: $('#modalCreateAd')
            });
        } else {
            _disable_ad_category("disabled");
            _required_category("")
        }
    };

    const generateId = (length) => {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    };

    const handleFileUpload = (event) => {
        if(event.target.files[0] !== undefined && $('#image').parsley().isValid() !== false) {
            let file = event.target.files[0], reader = new FileReader();
            reader.onload = function(r){
                _img_preview(r.target.result);
            };
            reader.readAsDataURL(file);

            _img_src(generateId(20));
            _img_name(event.target.files[0].name);
            _ad_img(event.target.files[0]);
        }
    };

    const handleNext = () => {
        let campaign = $('#campaign').parsley();
        let budget = $('#budget').parsley();

        if(campaign.isValid() && budget.isValid()) {
            balance(0, "topup");
            $('#campaign_basic .active').parent().next('li').find('a').trigger('click');
        }
    };

    const handlePrevious = () => {
        $('#campaign_basic .active').parent().prev('li').find('a').trigger('click');
    };

    const handleAdBasicNext = () => {
        let ad_name = $('#ad_name').parsley();
        let ad_budget = $('#ad_budget').parsley();
        let image = $('#image').parsley();

        if(ad_name.isValid() && image.isValid() && ad_budget.isValid()) {
            $('#ad_pill .active').parent().next('li').find('a').trigger('click');
        }
    };

    const handleAdDateNext = () => {
        let date_from = $('#date_from').parsley();
        let ad_duration = $('#ad_duration').parsley();

        if(date_from.isValid() && ad_duration.isValid()) {
            $('#ad_pill .active').parent().next('li').find('a').trigger('click');
        }
    };

    const handleAdTimeNext = () => {
        let custom_time = $('#custom_time').parsley();
        let start_hour = $('#start_hour').parsley();
        let end_hour = $('#end_hour').parsley();
        let ad_category = $('#ad_category').parsley();
        let category_data = $('#category_data').parsley();

        if(custom_time.isValid() && start_hour.isValid() && end_hour.isValid() && ad_category.isValid() && disable_ad_category === "disabled" || category_data.isValid()) {
            $('#ad_pill .active').parent().next('li').find('a').trigger('click');
        }
    };

    const handleAdCostNext = () => {
        $('#ad_pill .active').parent().next('li').find('a').trigger('click');
    };

    const handleAdGenderNext = () => {
        let gender = $('#gender').parsley();
        let b1a = $('#b1a').parsley();
        let b2a = $('#b2a').parsley();
        let b3a = $('#b3a').parsley();
        let b4a = $('#b4a').parsley();

        if(gender.isValid() && b1a.isValid() || b2a.isValid() || b3a.isValid() || b4a.isValid()) {
            $('#ad_pill .active').parent().next('li').find('a').trigger('click');
        }
    };

    const handleAdPrevious = (event) => {
        event.preventDefault();
        $('#ad_pill .active').parent().prev('li').find('a').trigger('click');
    };

    const handleDurationSelected = (event) => {
        _ad_duration(event.target.value);
        calculateClickRate($('#custom_cost').val(), event.target.value, $('#ad_budget').val());
    };

    const handleCustomTimeSelected = (event) => {
        _custom_time(event.target.value);
        if(event.target.value !== "normal") {
            $("#ad_category").append('<option value="All Categories">All Categories</option>');
            $("#category_data").empty().select2();
            _ad_category("All Categories");
            _disable_category("disabled");
            _disable_ad_category("disabled");
            _disable_hour("");
            _start_hour(0);
            _end_hour(23);
        } else {
            $("#ad_category option[value='All Categories']").remove();
            $("#category_data").empty().select2();
            _ad_category("");
            _disable_category("");
            _disable_hour("disabled");
            _start_hour(0);
            _end_hour(23);
        }
    };

    const handleStartHourSelected = (event) => {
        _start_hour(event.target.value);
    };

    const handleEndHourSelected = (event) => {
        _end_hour(event.target.value);
    };

    const handleCustomCostSelected = (event) => {
        _cost_model(event.target.value);

        if(event.target.value == "cpc") {
            calculateClickRate("cpc", $('#ad_duration').val(), $('#ad_budget').val());
        } else if(event.target.value == "cpa") {
            calculateClickRate("cpa", $('#ad_duration').val(), $('#ad_budget').val());
        } else if(event.target.value == "cpm") {
            calculateClickRate("cpm", $('#ad_duration').val(), $('#ad_budget').val());
        }
    };

    const calculateClickRate = (type, duration, value) => {
        switch (type) {
            case "cpm":
                _uri_boolean("disabled");
                _total_clicks((parseInt(value) / 0.20).toFixed(0));
                _click_per_day(((parseInt(value) / 0.20) / parseInt(duration)).toFixed(0));
                break;

            case "cpc":
                _uri_boolean("");
                _total_clicks((parseInt(value) / 0.30).toFixed(0));
                _click_per_day(((parseInt(value) / 0.30) / parseInt(duration)).toFixed(0));
                break;

            case "cpa":
                _uri_boolean("");
                _total_clicks((parseInt(value) / 0.50).toFixed(0));
                _click_per_day(((parseInt(value) / 0.50) / parseInt(duration)).toFixed(0));
                break;
        }
    };

    const handleUrl = (event) => {
        _target_url(event.target.value);
    };

    const handleGender = (event) => {
        if(event.target.value.length > 0) {
            _gender(event.target.value);
        }
    };

    const handleRegionType = (event) => {
        let type = event.target.value;
        if(event.target.value !== "" && event.target.value !== "national") {
            _regionType(type);
            _disable_region("");

            $(".ad-select2").empty().select2();

            $('.ad-select2').select2({
                minimumInputLength: 3,
                ajax: {
                    url: GET_REGION_DATA,
                    dataType: 'json',
                    type: "POST",
                    headers: {
                        "Authorization" : token,
                    },
                    data: function (query) {
                        return {
                            type: type,
                            query: query,
                            country: auth.user.country
                        };
                    },
                    processResults: function (data) {
                        return {
                            results: $.map(data, function (item) {
                                return {
                                    text: item,
                                    id: item
                                }
                            })
                        };
                    }
                },
                dropdownParent: $('#modalCreateAd')
            });
        } else {
            _regionType(type);
            $(".ad-select2").empty().select2();
            _region_required("");
            _disable_region("disabled");
        }
    };

    const balance = (value, type) => {
        if(type === "add") {
            _fbalance(parseInt(fbalance) + parseInt(value));
        } else if(type === "topup") {
            const total = ads.reduce((acc, item) => acc+= parseInt(item.budget), 0);
            _fbalance(budget - total);
        } else {
            _fbalance(parseInt(fbalance) - value);
        }
    };

    const rateBudget = (amount) => {
        if(auth.user.type === "agent") {
            return amount - amount * 0.15;
        }
        return amount;
    };

    const handleModal = () => {
        _type("add");
        if(fbalance == 0) {
            return cogoToast.error("Please increase your clicks budget or reduce the number of clicks on chosen campaign.", option);
        }

        if(ads.length >= 5) {
            return cogoToast.error("You can only upload maximum of 5 Ads.");
        }

        _img_required("required");
        _switch_button(false);

        $('#modalCreateAd').modal({
            backdrop: 'static',
            keyboard: false
        });

        $('#basic_form').parsley();
        $('#date_form').parsley();
        $('#time_form').parsley();
        $('#cost_form').parsley();
        $('#gender_form').parsley();
        $('#target_form').parsley();

        $("#basic_form").on('submit', function(e){
            e.preventDefault();
        });

        $("#date_form").on('submit', function(e){
            e.preventDefault();
        });

        $("#time_form").on('submit', function(e){
            e.preventDefault();
        });

        $("#cost_form").on('submit', function(e){
            e.preventDefault();
        });

        $("#gender_form").on('submit', function(e){
            e.preventDefault();
        });

        $("#target_form").on('submit', function(e){
            e.preventDefault();
        });

        $("#modalCreateAd").modal("show");
    };

    const handleCloseModal = () => {
        _ad_name('');
        _ad_budget('');
        _ad_category('');
        _ad_duration('');
        _sbalance('');
        _img_src('');
        _img_preview('');
        _start_date('');
        _cost_model("cpm");
        _start_hour(1);
        _end_hour(1);
        _total_clicks(0);
        _click_per_day(0);
        _gender('');
        _target_url('');
        _disable_hour('');
        _disable_region("disabled");
        _img_disabled("");
        _custom_cost("cpm");
        _custom_time('');
        _regionType('');
        _regionData([]);
        _uri_boolean("disabled");
        _age_group_a(false);
        _age_group_b(false);
        _age_group_c(false);
        _age_group_d(false);
        _img_required("required");
        _disable_ad_category("disabled");
        _region_required("required");

        $('#b1a').prop({checked: false});
        $('#b2a').prop({checked: false});
        $('#b3a').prop({checked: false});
        $('#b4a').prop({checked: false});

        $(".ad-select2").empty().select2();
        $("#category_data").empty().select2();

        $('#image').val(null);
        $('#date_from').val(null);
        $('#ad_duration').val(null);
        $('#ad_budget').val(null);
        $('#custom_cost').val(null);

        $('#basic_form').parsley().destroy();
        $('#date_form').parsley().destroy();
        $('#time_form').parsley().destroy();
        $('#cost_form').parsley().destroy();
        $('#gender_form').parsley().destroy();
        $('#target_form').parsley().destroy();
    };

    const handleCreateAd = () => {

        // let date_from = $('#date_from').parsley();
        // let ad_duration = $('#ad_duration').parsley();

        if(
            $('#ad_name').parsley().isValid() == false ||
            $('#ad_budget').parsley().isValid() == false ||
            $('#ad_category').parsley().isValid() == false ||
            $('#image').parsley().isValid() == false ||
            $('#ad_duration').parsley().isValid() == false ||
            $('#custom_cost').parsley().isValid() == false ||
            $('#custom_time').parsley().isValid() == false ||
            $('#date_from').parsley().isValid() == false ||
            $('#gender').parsley().isValid() == false ||
            $('#region_type').parsley().isValid() == false ||
            $('#region_type').parsley().isValid() == true && $('#region_data').parsley().isValid() == false
        ) {
            return null;
        }

        $("#modalCreateAd").modal("hide");
        $(".modal-backdrop").remove();

        let data_raw = {
            index: ads.length + 1,
            name : ad_name,
            img_src: img_src,
            budget : ad_budget,
            duration : ad_duration,
            category : ad_category,
            start_date : $('#date_from').val(),
            custom_cost : {
                type : custom_time,
                start_hour : start_hour,
                end_hour : end_hour
            },
            cost_model : cost_model,
            total_clicks : total_clicks,
            click_per_day : click_per_day,
            uri : target_url,
            gender : gender,
            extra_category: $('#category_data').val(),
            age : {
                group_a : age_group_a,
                group_b : age_group_b,
                group_c : age_group_c,
                group_d : age_group_d
            },
            region : {
                type : regionType,
                data : $('#region_data').val()
            }
        };

        let data = {
            index: ads.length + 1,
            img_preview: img_preview,
            ad_img: ad_img,
            name : ad_name,
            img_name: img_name,
            img_src: img_src,
            budget : ad_budget,
            duration : ad_duration,
            category : ad_category,
            start_date : $('#date_from').val(),
            custom_cost : {
                type : custom_time,
                start_hour : start_hour,
                end_hour : end_hour
            },
            cost_model : cost_model,
            total_clicks : total_clicks,
            click_per_day : click_per_day,
            uri : target_url,
            gender : gender,
            extra_category: $('#category_data').val(),
            age : {
                group_a : age_group_a,
                group_b : age_group_b,
                group_c : age_group_c,
                group_d : age_group_d
            },
            region : {
                type : regionType,
                data : $('#region_data').val()
            }
        };

        _ad_name('');
        _ad_budget('');
        _ad_category('');
        _ad_duration('');
        _fbalance('');
        _img_src('');
        _img_preview('');
        _start_date('');
        _cost_model("cpm");
        _start_hour(1);
        _end_hour(1);
        _total_clicks(0);
        _click_per_day(0);
        _gender('');
        _target_url('');
        _disable_hour('');
        _disable_region("disabled");
        _custom_cost("cpm");
        _custom_time('');
        _regionType('');
        _regionData([]);
        _uri_boolean("disabled");
        _age_group_a(false);
        _age_group_b(false);
        _age_group_c(false);
        _age_group_d(false);
        _img_required("required");
        _region_required("requried");
        _ads_raw([...ads_raw, data_raw]);
        _ads([...ads, data]);
        upload_image.push({
            index: ads.length + 1,
            raw: ad_img
        });

        _upload_image(upload_image);

        balance(ad_budget, "subtract");

        $('#b1a').prop({checked: false});
        $('#b2a').prop({checked: false});
        $('#b3a').prop({checked: false});
        $('#b4a').prop({checked: false});

        $(".ad-select2").empty().select2();
        $("#category_data").empty().select2();
        $('#ad_duration').val(null);
        $('#ad_budget').val(null);
        $('#custom_cost').val(null);

        $('#image').val(null);
        $('#date_from').val(null);

        $('#basic_form').parsley().destroy();
        $('#date_form').parsley().destroy();
        $('#time_form').parsley().destroy();
        $('#cost_form').parsley().destroy();
        $('#gender_form').parsley().destroy();
        $('#target_form').parsley().destroy();
    };

    const handlePayment = () => {
        const formData = new FormData();

        $.each(upload_image, function(i, file) {
            formData.append('file', file.raw);
        });

        formData.append('name', campaign_name);
        formData.append('budget', budget);
        formData.append('ads', JSON.stringify(ads_raw));

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        _previous("disabled");
        setStateReady(true);

        axios.post(UPLOAD_CAMPAIGN_URL, formData, config).then((response) => {
            let {payload, campaignId} = response.data;
            if(payload === "success") {
                // payment(campaign_name, budget, campaignId, auth.user.sub, auth.user.business_name);
            }
        }).catch((err) => {
            _previous("");
            setStateReady(false);

            const option = {
                hideAfter: 3
            };

            cogoToast.error(err.response.data.error, option);
        });
    };

    const handleDeleteAd = (budget, index) => {
        let filteredArray = ads.filter(item => item.index !== index);
        let _filteredArray = ads_raw.filter(item => item.index !== index);
        let imageArray = upload_image.filter(item => item.index !== index);
        _ads(filteredArray);
        _ads_raw(_filteredArray);
        _upload_image(imageArray);
        balance(budget, "add");
    };

    const handleEditAdModal = (result, index) => {

        $('.category-select2').select2({
            minimumResultsForSearch: Infinity,
            placeholder: 'Select',
            dropdownParent: $('#modalCreateAd')
        });

        $('.ad-select2').select2({
            minimumInputLength: 3,
            ajax: {
                url: GET_REGION_DATA,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization" : token,
                },
                data: function (query) {
                    return {
                        type: type,
                        query: query,
                        country: auth.user.country
                    };
                },
                processResults: function (data) {
                    return {
                        results: $.map(data, function (item) {
                            return {
                                text: item,
                                id: item
                            }
                        })
                    };
                }
            },
            dropdownParent: $('#modalCreateAd')
        });

        _img_required("");
        _img_disabled("disabled");
        _switch_button(true);
        __index(index);
        _sbalance(parseInt(result.budget) + parseInt(fbalance));

        // $('#ad_category option:contains('+result.ad_category+')').prop('selected', true);

        _ad_name(result.name);
        _ad_budget(result.budget);
        _ad_img(result.ad_img);
        _img_src(result.img_src);
        _img_name(result.img_name);
        _ad_category(result.category);
        _extra_category(result.extra_category);
        _cost_model(result.cost_model);
        _ad_duration(result.duration);
        _custom_time(result.custom_cost.type);
        _start_hour(result.custom_cost.start_hour);
        _end_hour(result.custom_cost.end_hour);
        _total_clicks(result.total_clicks);
        _click_per_day(result.click_per_day);
        _gender(result.gender);
        _regionType(result.region.type);
        _disable_region('');

        if(result.region.type === "national") {
            _disable_region("disabled");
            _region_required("");
        }

        if(result.region.data.length > 0) {
            for(let i = 0; i < result.region.data.length; ++i) {
                let option = [];
                option[i] = new Option(result.region.data[i], result.region.data[i]);
                option[i].selected = true;

                $("#region_data").append(option).trigger("change");
            }
        }

        if(result.extra_category.length > 0) {
            for(let i = 0; i < result.extra_category.length; ++i) {
                let option = [];
                option[i] = new Option(result.extra_category[i], result.extra_category[i]);
                option[i].selected = true;

                $("#category_data").append(option).trigger("change");
            }
        }

        if(result.custom_cost.type === "normal") {
            _disable_hour("disabled");
            _start_hour(result.custom_cost.start_hour);
            _end_hour(result.custom_cost.start_hour);
        } else {
            _disable_hour("");
            _start_hour(result.custom_cost.start_hour);
            _end_hour(result.custom_cost.end_hour);
        }

        $('#b1a').prop({checked: result.age.group_a});
        $('#b2a').prop({checked: result.age.group_b});
        $('#b3a').prop({checked: result.age.group_c});
        $('#b4a').prop({checked: result.age.group_d});

        $('#date_from').val(result.start_date);

        $('#modalCreateAd').modal({
            backdrop: 'static',
            keyboard: false
        });

        $('#ad').parsley();
        $("#modalCreateAd").modal("show");
    };

    const handleAdUpdate = () => {
        if(
            $('#ad_name').parsley().isValid() === false ||
            $('#ad_budget').parsley().isValid() === false ||
            $('#ad_category').parsley().isValid() === false ||
            $('#image').parsley().isValid() === false ||
            $('#ad_duration').parsley().isValid() === false ||
            $('#custom_cost').parsley().isValid() === false ||
            $('#custom_time').parsley().isValid() === false ||
            $('#date_from').parsley().isValid() === false ||
            $('#gender').parsley().isValid() === false ||
            $('#region_type').parsley().isValid() === false ||
            $('#region_type').parsley().isValid() === true && $('#region_data').parsley().isValid() === false
        ) {
            return null;
        }

        $("#modalCreateAd").modal("hide");
        $(".modal-backdrop").remove();

        let filteredArray = ads.filter(item => item.index !== _index);
        let _filteredArray = ads_raw.filter(item => item.index !== _index);
        let imageArray = upload_image.filter(item => item.index !== _index);
        let findDataArray = ads.filter(item => item.index === _index);

        let data_raw = {
            index: _index,
            name : ad_name,
            budget : ad_budget,
            img_src: img_src,
            duration : ad_duration,
            category : ad_category,
            extra_category: $('#category_data').val(),
            start_date : $('#date_from').val(),
            custom_cost : {
                type : custom_time,
                start_hour : start_hour,
                end_hour : end_hour
            },
            cost_model : cost_model,
            total_clicks : total_clicks,
            click_per_day : click_per_day,
            uri : target_url,
            gender : gender,
            age : {
                group_a : age_group_a,
                group_b : age_group_b,
                group_c : age_group_c,
                group_d : age_group_d
            },
            region : {
                type : regionType,
                data : $('#region_data').val()
            }
        };

        let data = {
            index: _index,
            img_preview: img_preview || findDataArray[0].img_preview,
            name : ad_name,
            budget : ad_budget,
            duration : ad_duration,
            category : ad_category,
            extra_category: $('#category_data').val(),
            start_date : $('#date_from').val(),
            custom_cost : {
                type : custom_time,
                start_hour : start_hour,
                end_hour : end_hour
            },
            cost_model : cost_model,
            total_clicks : total_clicks,
            click_per_day : click_per_day,
            uri : target_url,
            gender : gender,
            age : {
                group_a : age_group_a,
                group_b : age_group_b,
                group_c : age_group_c,
                group_d : age_group_d
            },
            region : {
                type : regionType,
                data : $('#region_data').val()
            }
        };

        // if(filteredArray.length >= 0) {
        //     filteredArray.push(data);
        //     _filteredArray.push(data_raw);
        // }
        filteredArray.push(data);
        _filteredArray.push(data_raw);

        // let findImageArray = upload_image.filter(item => item.name === img_name);
        // let _imageArray;
        //
        // if(findImageArray.length === 0) {
        //     _imageArray = upload_image.filter(item => item.index !== img_name);
        //     _imageArray.push(ad_img);
        // }
        imageArray.push({
            index: ads.length + 1,
            raw: ad_img
        });

        let total = sbalance - ad_budget;

        _ads_raw(_filteredArray);
        _ads(filteredArray);
        _upload_image(imageArray);
        _fbalance(total);

        _ad_name('');
        _sbalance('');
        _ad_budget('');
        _ad_duration('');
        _img_preview('');
        _start_date('');
        _cost_model("cpm");
        _start_hour(1);
        _end_hour(1);
        _click_per_day(0);
        _total_clicks(0);
        _gender('');
        _target_url('');
        _disable_hour('');
        _disable_region("disabled");
        _img_required('required');
        _img_disabled('');
        _custom_cost("cpm");
        _custom_time('');
        _regionType('');
        _regionData([]);
        _uri_boolean("disabled");

        $('#b1a').prop({checked: false});
        $('#b2a').prop({checked: false});
        $('#b3a').prop({checked: false});
        $('#b4a').prop({checked: false});

        $(".ad-select2").empty().select2();
        $("#category_data").empty().select2();
        $('#category_data').select2('destroy');

        $('#image').val(null);
        $('#date_from').val(null);

        $('#basic_form').parsley().destroy();
        $('#date_form').parsley().destroy();
        $('#time_form').parsley().destroy();
        $('#cost_form').parsley().destroy();
        $('#gender_form').parsley().destroy();
        $('#target_form').parsley().destroy();
    };

    const limitBudget = () => {
        const total = ads.reduce((acc, item) => acc+= parseInt(item.budget), 0);
        if(total <= 200) {
            return 200;
        } else {
            return total;
        }
    };

    const handleAdValidate = () => {
        if(ads.length === 0) {
            return cogoToast.error("Please add one or more ads before you proceed.");
        }

        if(fbalance !== 0) {
            return cogoToast.error("You still have balance left. Please add more ads.", option);
        }

        $('.nav-line .active').parent().next('li').find('a').trigger('click');
    };

    const handle_group_a = () => {
        if($('#b1a').is(':checked')){
            _age_group_a(true);
        } else {
            _age_group_a(false);
        }
    };

    const handle_group_b = () => {
        if($('#b2a').is(':checked')){
            _age_group_b(true);
        } else {
            _age_group_b(false);
        }
    };

    const handle_group_c = () => {
        if($('#b3a').is(':checked')){
            _age_group_c(true);
        } else {
            _age_group_c(false);
        }
    };

    const handle_group_d = () => {
        if($('#b4a').is(':checked')){
            _age_group_d(true);
        } else {
            _age_group_d(false);
        }
    };

    const start_hours = Array(24 - 1).fill(1).map((x, y) => x + y);
    const end_hours = Array(24 - parseInt(start_hour)).fill(parseInt(start_hour) + 1).map((x, y) => x + y);

    let renderAd = () => {
        if(ads.length === 0) {
            return(
                <div className="row row-sm">
                    <div className="container d-flex justify-content-center ht-350">
                        <div
                            className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                            <div className="wd-80p wd-sm-300 mg-b-15 tx-center"><i className="wd-60 ht-60 tx-color-03" data-feather="image"/></div>
                            <p className="tx-color-03 mg-b-30 tx-center">Accepted image files: PNG, JPEG, GIF.
                                <br/>Resolution Min: 1280x720 upward<br/>Maximum file size: 1MB</p>
                            <button className="btn btn-brand-02 outline-none" onClick={handleModal.bind()} data-toggle="modal"><i data-feather="plus"/> Create</button>
                        </div>
                    </div>
                </div>
            )
        } else {

            const renderAdItems = ads.map((result, index) => {
                return(
                    <div key={index} className="col-lg-4 col-md-6">
                        <div className="card card-body pd-t-8 pd-l-8 pd-b-8 mg-b-20 pd-b-4">
                            <div className="media">
                                <img src={result.img_preview} className="ht-120 wd-85 rounded mg-r-10" alt=""/>
                                <div className="media-body">
                                    <h6 className="tx-uppercase tx-12 tx-spacing-1 tx-color-02 tx-bold mg-b-8">{result.name}</h6>
                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-8">
                                        <span className="tx-semibold">Budget:</span> {result.budget}</h6>
                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-8">
                                        <span className="tx-semibold">Click:</span> {result.total_clicks}</h6>
                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-8">
                                        <span className="tx-semibold">Cost Model:</span> {result.cost_model}</h6>
                                    <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-5">
                                        <span className="tx-semibold">Duration:</span> {result.duration}</h6>
                                    <nav className="nav nav-icon-only">
                                        <NavLink to="#" className="nav-link" onClick={
                                            handleDeleteAd.bind(null, result.budget, result.index)
                                        }><i className="fa fa-trash-alt"/> Delete</NavLink>
                                        {/*<NavLink to="#" className="nav-link" onClick={*/}
                                        {/*    handleEditAdModal.bind(null, result, result.index)*/}
                                        {/*}*/}
                                        {/*><Edit3 size={15}/> Edit</NavLink>*/}
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            });

            return(
                <div className="row row-sm">
                    {renderAdItems}
                    <div className="col-lg-4 col-md-6 container d-flex justify-content-center">
                        <div
                            className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                            <p className="tx-color-03 mg-b-10 tx-center">Accepted image files: PNG, JPEG, GIF.<br/>Resolution
                                Min: 1280x720 upward<br/>Maximum file size: 1MB</p>
                            <button className="btn btn-sm btn-brand-02 outline-none" onClick={handleModal.bind()} data-toggle="modal"><i data-feather="plus"/> Create</button>
                        </div>
                    </div>
                </div>
            );
        }
    };

    let renderTargetUrl = () => {
        if(uri_boolean !== "disabled") {
            return(
                <div id="urlWrapper" className="form-group parsley-input">
                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Website Link</label>
                    <input type="url" className="form-control"
                           data-parsley-type='url'
                           value={target_url}
                           onChange={handleUrl.bind()}
                           placeholder="Enter website link"
                           autoComplete="off"
                           disabled={uri_boolean}
                           data-parsley-class-handler="#urlWrapper"
                           required/>
                </div>
            )
        } else {
            return(
                <div id="urlWrapper" className="form-group parsley-input">
                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Website Link</label>
                    <input type="url" className="form-control"
                           data-parsley-type='url'
                           value={target_url}
                           onChange={handleUrl.bind()}
                           placeholder="Not Applicable"
                           autoComplete="off"
                           disabled
                           data-parsley-class-handler="#urlWrapper"
                    />
                </div>
            )
        }
    };

    let renderSwitchButton = () => {
        if(switch_button == true) {
            return(
                <button className="btn btn-success float-right" onClick={handleAdUpdate.bind()}>Update</button>
            );
        }

        return(
            <button className="btn btn-success float-right" onClick={handleCreateAd.bind()}><i className="fa fa-check-circle"/> Finish</button>
        );
    };

    let renderProceedButton = () => {
        if(stateReady == true) {
            return(
                <button className="btn btn-brand-02 float-right" disabled>
                    <span className="spinner-border spinner-border-sm mg-r-10" aria-hidden="true"/>Please Wait</button>
            )
        } else {
            return(
                <button className="btn btn-brand-01 float-right" onClick={handlePayment.bind()}>Proceed <i className="ion-md-arrow-forward"/></button>
            );
        }
    };

    return (
        <div>
            <Header />
            <div className="content content-fixed">
                <div className="container container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Create</li>
                                </ol>
                            </nav>
                            <h4 className="mg-b-0 tx-spacing--1">Create Campaign</h4>
                        </div>
                    </div>
                    <div className="card card-body pd-x-25 pd-sm-x-30 pd-t-15 pd-sm-t-20 pd-b-15 pd-sm-b-20">
                        <div className="nav-wrapper mg-b-20 tx-13">
                            <div>
                                <ul id="campaign_basic" className="nav nav-line nav-fill tx-medium nav-disabled">
                                    <li className="nav-item"><a href="#basic" className="nav-link active" data-toggle="tab"><i className="ion-md-information-circle-outline"/> Basic</a></li>
                                    <li className="nav-item"><a href="#create_ads" className="nav-link" data-toggle="tab"><i className="ion-md-document"/> Create Ads</a></li>
                                    <li className="nav-item"><a href="#payment" className="nav-link" data-toggle="tab"><i className="ion-md-checkmark-circle-outline"/> Confirm</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="tab-content">
                            <div id="basic" className="tab-pane fade active show">
                                <form id="_basic" className="parsley-style-1" data-parsley-validate noValidate>
                                    <h5 className="tx-semibold">Basic Information</h5>
                                    <section>
                                        {/*<p className="mg-b-20">Try the keyboard navigation by clicking arrow left or right!</p>*/}
                                        <div className="row row-sm">
                                            <div id="cnWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Name<span className="tx-danger">*</span></label>
                                                <input id="campaign" className="form-control"
                                                       name="campaign"
                                                       placeholder="Enter campaign name" type="text"
                                                       value={campaign_name}
                                                       autoComplete="off"
                                                       onChange={handleCampaignNameChange.bind()}
                                                       data-parsley-class-handler="#cnWrapper" required/>
                                            </div>
                                            <div id="bgWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Budget<span className="tx-danger">*</span></label>
                                                <input id="budget" className="form-control" name="budget"
                                                       placeholder="Enter budget" type="number"
                                                       value={budget}
                                                       min={limitBudget()}
                                                       autoComplete="off"
                                                       onChange={handleBudgetChange.bind()}
                                                       data-parsley-class-handler="#bgWrapper" required/>
                                            </div>
                                        </div>
                                    </section>
                                    <hr/>
                                    <button className="btn btn-brand-01 float-right btnNextFront" onClick={handleNext.bind()}>Next <i className="ion-md-arrow-forward"/></button>
                                </form>
                            </div>
                            <div id="create_ads" className="tab-pane fade">
                                <h5 className="tx-semibold">Create Ads</h5>
                                <section>
                                    <p className="mg-b-20">Balance: R{sbalance || fbalance}</p>
                                    {renderAd()}
                                </section>
                                <hr/>
                                <button className="btn btn-dark float-left btnPrevious" onClick={handlePrevious.bind()}><i className="ion-md-arrow-back"/> Back</button>
                                <button className="btn btn-brand-01 float-right btnNextAd" onClick={handleAdValidate.bind()}>Next <i className="ion-md-arrow-forward"/></button>
                            </div>
                            <div id="payment" className="tab-pane fade">
                                <h5 className="tx-semibold">Payment Details</h5>
                                <section>
                                    <div className="ht-100">
                                        <p className="mg-b-5">Campaign: {campaign_name}</p>
                                        <p className="mg-b-5">Budget: R{budget}</p>
                                        <p className="mg-b-10">Upload Ads: {ads.length}</p>
                                    </div>
                                    <hr/>
                                    <div className="d-flex justify-content-end">
                                        <p className="mg-b-5"><strong>Subtotal:</strong> R{budget}<br/>
                                            <strong>15% Discount:</strong> R0<br/>
                                            <strong>Total:</strong> R{rateBudget(budget)}</p>
                                    </div>
                                </section>
                                <hr/>
                                <button className="btn btn-dark float-left btnPrevious" disabled={previous} onClick={handlePrevious.bind()}><i className="ion-md-arrow-back"/> Back</button>
                                {renderProceedButton()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalCreateAd" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                    <div className="modal-content">
                        <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                            <div className="media align-items-center">
                                <div className="media-body">
                                    <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal.bind()}>
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <h4 className="tx-18 tx-sm-20 mg-b-2">Create Ad</h4>
                                    <p className="tx-13 tx-color-02 mg-b-0">Balance: R{sbalance || fbalance}</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                            <div className="nav-wrapper mg-b-20 tx-13">
                                <ul id="ad_pill" className="nav nav-line nav-fill tx-medium nav-disabled">
                                    <li className="nav-item"><a href="#step1" className="nav-link active" data-toggle="tab"><i className="ion-md-information-circle-outline"/> Basic</a></li>
                                    <li className="nav-item"><a href="#step2" className="nav-link" data-toggle="tab"><i className="ion-md-calendar"/> Date</a></li>
                                    <li className="nav-item"><a href="#step3" className="nav-link" data-toggle="tab"><i className="ion-md-stopwatch"/> Time</a></li>
                                    <li className="nav-item"><a href="#step4" className="nav-link" data-toggle="tab"><i className="ion-md-wallet"/> Cost</a></li>
                                    <li className="nav-item"><a href="#step5" className="nav-link" data-toggle="tab"><i className="ion-md-male"/> Gender</a></li>
                                    <li className="nav-item"><a href="#step6" className="nav-link" data-toggle="tab"><i className="ion-md-navigate"/> Target</a></li>
                                </ul>
                            </div>

                            <div className="tab-content">
                                <div id="step1" className="tab-pane fade active show">
                                    <form id="basic_form" className="parsley-style-1">
                                        <h7 className="tx-semibold">Basic Information</h7>
                                        <section>
                                            <p className="mg-b-20">Try the keyboard navigation by clicking arrow left or right!</p>
                                            <div id="adWrapper" className="form-group parsley-input">
                                                <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Ad Name</label>
                                                <input id="ad_name" type="text" className="form-control"
                                                       placeholder="Enter ad name"
                                                       value={ad_name}
                                                       onChange={handleAdName.bind()}
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#adWrapper" required/>
                                            </div>

                                            <div className="form-group">
                                                <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Select Images</label>
                                                <input id="image" type="file" className="form-control"
                                                       onChange={handleFileUpload.bind()}
                                                       data-parsley-filemaxmegabytes="1"
                                                       data-parsley-trigger="change"
                                                       data-parsley-filemimetypes="image/jpeg, image/png, image/gif, image/jpg"
                                                       disabled={img_disabled}
                                                       required={img_required}/>
                                            </div>

                                            <div id="budgetWrapper" className="form-group parsley-input">
                                                <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Allocate Budget</label>
                                                <input id="ad_budget" type="number" className="form-control"
                                                       placeholder="Enter budget required"
                                                       value={ad_budget}
                                                       min={200}
                                                       max={sbalance || fbalance}
                                                       onChange={handleAdBudget.bind()}
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#budgetWrapper" required/>
                                            </div>
                                            <hr/>
                                            <button className="btn btn-brand-01 float-right btnNextFront" onClick={handleAdBasicNext.bind()}>Next <i className="fa fa-arrow-alt-right"/></button>
                                        </section>
                                    </form>
                                </div>

                                <div id="step2" className="tab-pane fade">
                                    <form id="date_form" className="parsley-style-1">
                                        <h7 className="tx-semibold">Create Ads</h7>
                                        <section>
                                            <p className="mg-b-20">Try the keyboard navigation by clicking arrow left or right!</p>
                                            <div id="startWrapper" className="form-group parsley-input">
                                                <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Select Start Date</label>
                                                <input type="text"
                                                       id="date_from"
                                                       className="form-control"
                                                       placeholder="Select Start Date"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#startWrapper" required
                                                       readOnly
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Duration</label>
                                                <select className="custom-select"
                                                        id="ad_duration"
                                                        value={ad_duration}
                                                        onChange={handleDurationSelected.bind()}
                                                        required>
                                                    <option value="" disabled>Select</option>
                                                    {duration.map((result) => <option key={result.name} value={result.name}>{result.name}</option>)}
                                                </select>
                                            </div>
                                        </section>
                                        <hr/>
                                        <button className="btn btn-dark float-left btnPrevious" onClick={handleAdPrevious.bind()}><i className="fa fa-arrow-alt-left"/> Back</button>
                                        <button className="btn btn-brand-01 float-right btnNextAd" onClick={handleAdDateNext.bind()}>Next <i className="fa fa-arrow-alt-right"/></button>
                                    </form>
                                </div>

                                <div id="step3" className="tab-pane fade">
                                    <form id="time_form" className="parsley-style-1">
                                        <h7 className="tx-semibold">Customize Time</h7>
                                        <section>
                                            <p className="mg-b-20">The next and previous buttons help you to navigate through your content.</p>
                                            <div className="row row-sm">
                                                <div className="form-group col-12">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Custom Time</label>
                                                    <select className="custom-select"
                                                            id="custom_time"
                                                            value={custom_time}
                                                            onChange={handleCustomTimeSelected.bind()}
                                                            required>
                                                        <option value="" disabled>Select</option>
                                                        <option value="normal">Full Time</option>
                                                        <option value="custom">Custom Time</option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Start Hour</label>
                                                    <select className="custom-select"
                                                            id="start_hour"
                                                            disabled={disable_hour}
                                                            value={start_hour}
                                                            onChange={handleStartHourSelected.bind()}
                                                            required>
                                                        <option value="" disabled>Select</option>
                                                        {start_hours.map((result) => <option key={result} value={result}>{result}</option>)}
                                                    </select>
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">End Hour</label>
                                                    <select className="custom-select"
                                                            id="end_hour"
                                                            disabled={disable_hour}
                                                            value={end_hour}
                                                            onChange={handleEndHourSelected.bind()}
                                                            required>
                                                        <option value="" disabled>Select</option>
                                                        {end_hours.map((result) => <option key={result} value={result}>{result}</option>)}
                                                    </select>
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Category</label>
                                                    <select className="custom-select"
                                                            value={ad_category}
                                                            id="ad_category"
                                                            onChange={handleCategorySelected.bind()}
                                                            disabled={disable_category}
                                                            required>
                                                        <option value="" disabled>Select</option>
                                                        {interest.map((result) => <option key={result.name} value={result.name}>{result.name}</option>)}
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-12">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Additional Category (Optional)</label>
                                                    <select className="form-control wd-438 category-select2" multiple="multiple"
                                                            data-parsley-class-handler="#categoryWrapper"
                                                            disabled={disable_ad_category}
                                                            id="category_data"
                                                            required={required_category}>
                                                        <option value="" disabled>Disabled</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <hr/>
                                            <button className="btn btn-dark float-left btnPrevious" onClick={handleAdPrevious.bind()}><i className="fa fa-arrow-alt-left"/> Back</button>
                                            <button className="btn btn-brand-01 float-right btnNextAd" onClick={handleAdTimeNext.bind()}>Next <i className="fa fa-arrow-alt-right"/></button>
                                        </section>
                                    </form>
                                </div>

                                <div id="step4" className="tab-pane fade">
                                    <form id="cost_form" className="parsley-style-1">
                                        <h7 className="tx-semibold">Customize Cost</h7>
                                        <section>
                                            <p className="mg-b-20">The next and previous buttons help you to navigate through your content.</p>
                                            <div className="row row-sm">
                                                <div className="form-group col-sm-12">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Custom Cost</label>
                                                    <select className="custom-select"
                                                            value={cost_model}
                                                            id="custom_cost"
                                                            onChange={handleCustomCostSelected.bind()}
                                                            required>
                                                        <option value="cpm">CPM - 0.20</option>
                                                        <option value="cpc">CPC - 0.30</option>
                                                        <option value="cpa">CPA - 0.50</option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-sm-12">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Total Clicks</label>
                                                    <input type="number"
                                                           disabled
                                                           value={total_clicks}
                                                           className="form-control"
                                                           placeholder="0"/>
                                                </div>

                                                <div className="form-group col-sm-12">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Clicks Per Day</label>
                                                    <input type="number"
                                                           disabled
                                                           value={click_per_day}
                                                           className="form-control"
                                                           placeholder="0"/>
                                                </div>
                                            </div>
                                        </section>
                                        <hr/>
                                        <button className="btn btn-dark float-left btnPrevious" onClick={handleAdPrevious.bind()}><i className="fa fa-arrow-alt-left"/> Back</button>
                                        <button className="btn btn-brand-01 float-right btnNextAd" onClick={handleAdCostNext.bind()}>Next <i className="fa fa-arrow-alt-right"/></button>
                                    </form>
                                </div>

                                <div id="step5" className="tab-pane fade">
                                    <form id="gender_form" className="parsley-style-1">
                                        <h7 className="tx-semibold">Demographics</h7>
                                        <section>
                                            <p className="mg-b-20">The next and previous buttons help you to navigate through your content.</p>
                                            <div className="row row-sm">
                                                <div className="form-group col-sm-12">
                                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Select Target Gender</label>
                                                    <select className="custom-select"
                                                            id="gender"
                                                            value={gender}
                                                            onChange={handleGender.bind()}
                                                            required>
                                                        <option value="" disabled>Select</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="both">Both</option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-sm-12">
                                                    <div data-label="Age Group" className="df-example mg-b-5">
                                                        <div id="cbWrapper2" className="parsley-checkbox pos-relative">
                                                            <div className="custom-control custom-checkbox">
                                                                <input className="custom-control-input" type="checkbox" name="age[]"
                                                                       data-parsley-mincheck="1"
                                                                       onChange={handle_group_a.bind()}
                                                                       data-parsley-class-handler="#cbWrapper2"
                                                                       data-parsley-errors-container="#cbErrorContainer2"
                                                                       required
                                                                       data-parsley-multiple="age" id="b1a"/>
                                                                <label className="custom-control-label" htmlFor="b1a">18 - 24</label>
                                                            </div>

                                                            <div className="custom-control custom-checkbox">
                                                                <input className="custom-control-input" type="checkbox"
                                                                       name="age[]"
                                                                       data-parsley-multiple="age"
                                                                       onChange={handle_group_b.bind()}
                                                                       id="b2a"/>
                                                                <label className="custom-control-label" htmlFor="b2a">25 - 34</label>
                                                            </div>

                                                            <div className="custom-control custom-checkbox">
                                                                <input className="custom-control-input" type="checkbox"
                                                                       name="age[]"
                                                                       data-parsley-multiple="age"
                                                                       onChange={handle_group_c.bind()}
                                                                       id="b3a"/>
                                                                <label className="custom-control-label" htmlFor="b3a">35 - 44</label>
                                                            </div>

                                                            <div className="custom-control custom-checkbox">
                                                                <input className="custom-control-input" type="checkbox"
                                                                       name="age[]"
                                                                       data-parsley-multiple="age"
                                                                       onChange={handle_group_d.bind()}
                                                                       id="b4a"/>
                                                                <label className="custom-control-label" htmlFor="b4a">45 and above</label>
                                                            </div>

                                                            <div id="cbErrorContainer2" className="wd-100p pos-absolute b-0 l-0"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <hr/>
                                        <button className="btn btn-dark float-left btnPrevious" onClick={handleAdPrevious.bind()}><i className="fa fa-arrow-alt-left"/> Back</button>
                                        <button className="btn btn-brand-01 float-right btnNextAd" onClick={handleAdGenderNext.bind()}>Next <i className="fa fa-arrow-alt-right"/></button>
                                    </form>
                                </div>

                                <div id="step6" className="tab-pane fade">
                                    <form id="target_form" className="parsley-style-1">
                                        <h7 className="tx-semibold">Target Advert</h7>
                                        <section>
                                            <p className="mg-b-20">The next and previous buttons help you to navigate through your content.</p>
                                            <div className="row row-sm">
                                                <div className="form-group col-sm-12">
                                                    <div className="d-flex justify-content-between mg-b-5">
                                                        <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Select Region</label>
                                                        <Link to="#" className="tx-11" data-container="body" data-toggle="popover" data-placement="right" data-content={region_info}>Learn More</Link>
                                                    </div>
                                                    <select className="custom-select"
                                                            value={regionType}
                                                            id="region_type"
                                                            onChange={handleRegionType.bind()}
                                                            required>
                                                        <option value="" disabled>Select</option>
                                                        <option value="national">National</option>
                                                        <option value="region">Provinces/State</option>
                                                        <option value="city">City</option>
                                                        <option value="suburb">Suburb/Townships</option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-12">
                                                    <div className="d-flex justify-content-between mg-b-5">
                                                        <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Enter Target Area(s)</label>
                                                        <Link to="/statistics" target="_blank" className="tx-11">Statistics</Link>
                                                    </div>
                                                    <div>
                                                        <select className="form-control wd-438 ad-select2" multiple="multiple"
                                                                data-parsley-class-handler="#regionWrapper"
                                                                id="region_data"
                                                                disabled={disable_region}
                                                                required={region_required}
                                                        >
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <hr/>
                                        <button className="btn btn-dark float-left btnPrevious" onClick={handleAdPrevious.bind()}><i className="fa fa-arrow-alt-left"/> Back</button>
                                        {renderSwitchButton()}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CreateAd;
