 import React, { useEffect } from 'react';
import { useDispatch} from 'react-redux';
import { Link } from 'react-router-dom';
import 'datatables.net';
import 'datatables.net-responsive';
import {setInvoice} from "../../redux/actions/invoiceActions";
import $ from "jquery";
import moment from "moment";

const InvoiceList = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        $('#invoice').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search...',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
            }
        });
    }, []);

    const routeParams = (docs) => {
        dispatch(setInvoice(docs, props.history));
    };

    const invoice = props.invoice.map((docs, index) => (
        <tr key={index}>
            <td>{docs.name}</td>
            <td>ADZ{docs.pf_paymentId}</td>
            <td>R{docs.amount}</td>
            <td className="tx-success tx-medium">{docs.status}</td>
            {/*<td className="campaign-paragraph">{docs.dateCreated}</td>*/}
            <td>
                <nav className="nav nav-icon-only">
                    <Link to="/invoice-report" className="nav-link" onClick={routeParams.bind(null, docs)}><i className="fa fa-eye"/> View</Link>
                </nav>
            </td>
        </tr>
    ));
    return (
        <div data-label="Campaign Lists" className="df-example demo-table">
            <table id="invoice" className="table">
                <thead>
                <tr>
                    <th className="wd-25p">Name</th>
                    <th className="wd-20p">Receipt #</th>
                    <th className="wd-20p">Amount</th>
                    <th className="wd-15p">Status</th>
                    <th className="wd-20p">Actions</th>
                </tr>
                </thead>
                <tbody>
                {invoice}
                </tbody>
            </table>
        </div>
    );
};

export default InvoiceList;
