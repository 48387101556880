import React from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import $ from "jquery";
import feather from "feather-icons";

const Failed = () => {
    document.title = "Failed";

    $(function () {
        feather.replace();
    });

    return (
        <div>
            <div className="content content-fixed content-auth-alt">
                <div className="container ht-100p">
                    <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                        <div className="container ht-100p">
                            <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                                <div className="wd-80p wd-sm-300 mg-b-15 tx-center"><i className="wd-60 ht-60 tx-danger" data-feather="x-circle"/></div>
                                <h4 className="tx-20 tx-sm-24">Payment Cancelled</h4>
                                <p className="tx-color-03 mg-b-40 tx-center">You cancelled the payemnt. Click on the button below to redirect you to home page.</p>
                                <div className="tx-13 tx-lg-14 mg-b-40">
                                    <Link to="/" className="btn btn-brand-02 d-inline-flex align-items-center">Take me to Home</Link>
                                    <a href="mailto:support@adzlok.co.za" className="btn btn-white d-inline-flex align-items-center mg-l-5">Contact Support</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="footer">
                <div>
                    <span>&copy; {moment().format("YYYY")} AdzLok. </span>
                    <span>All Right Reserved.</span>
                </div>
                <div>
                    <nav className="nav">
                        <Link to="/" className="nav-link">Home</Link>
                        <Link to="#" className="nav-link">Contact Us</Link>
                    </nav>
                </div>
            </footer>
        </div>
    );
};

export default Failed;
