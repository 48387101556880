const deposit = (name, amount, auth, business_name) => {
    payFast("https://www.payfast.co.za/eng/process", {
        merchant_id: "13186461",
        amount: amount,
        merchant_key: "njsto05l3xa0j",
        return_url: "https://dash.adzlok.co.za/#/success",
        cancel_url: "https://dash.adzlok.co.za/#/failed",
        notify_url: "https://dash.server.adzlok.co.za/v1/advertiser/payment",
        custom_str1: auth,
        custom_str2: business_name,
        item_name: name,
        item_description: `${amount} is deposited into credit account.`
    });
};

const payFast = (path, params, method) => {
    method = method || "post"; // Set method to post by default if not specified.

    let form = document.createElement("form");
    form.setAttribute("method", method);
    form.setAttribute("action", path);

    for(let key in params) {
        if(params.hasOwnProperty(key)) {
            let hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", params[key]);

            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
};

export default deposit;
