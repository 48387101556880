import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import Logo from "../../assets/img/invoice-logo.png";
import {df3} from './data/samples';
import $ from 'jquery';
import Chart from "chart.js";

const Try = () => {
    document.title = "Terms and Conditions";
    $(function () {
        'use strict'

        var ctxLabel = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var ctxData1 = [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30];
        var ctxData2 = [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20];
        var ctxColor1 = '#001737';
        var ctxColor2 = '#1ce1ac';

        // // Bar chart
        // var ctx1 = document.getElementById('chartBar1').getContext('2d');
        // new Chart(ctx1, {
        //     type: 'bar',
        //     data: {
        //         labels: ctxLabel,
        //         datasets: [{
        //             data: ctxData1,
        //             backgroundColor: ctxColor1
        //         }, {
        //             data: ctxData2,
        //             backgroundColor: ctxColor2
        //         }]
        //     },
        //     options: {
        //         maintainAspectRatio: false,
        //         responsive: true,
        //         legend: {
        //             display: false,
        //             labels: {
        //                 display: false
        //             }
        //         },
        //         scales: {
        //             yAxes: [{
        //                 gridLines: {
        //                     color: '#e5e9f2'
        //                 },
        //                 ticks: {
        //                     beginAtZero:true,
        //                     fontSize: 10,
        //                     fontColor: '#182b49',
        //                     max: 80
        //                 }
        //             }],
        //             xAxes: [{
        //                 gridLines: {
        //                     display: false
        //                 },
        //                 barPercentage: 0.6,
        //                 ticks: {
        //                     beginAtZero:true,
        //                     fontSize: 11,
        //                     fontColor: '#182b49'
        //                 }
        //             }]
        //         }
        //     }
        // });

        // Line chart
        var ctx4 = document.getElementById('chartLine1');
        new Chart(ctx4, {
            type: 'line',
            data: {
                labels: ctxLabel,
                datasets: [{
                    data: ctxData1,
                    borderColor: ctxColor1,
                    borderWidth: 1,
                    lineTension: 0,
                    fill: false
                },{
                    data: ctxData2,
                    borderColor: ctxColor2,
                    lineTension: 0,
                    borderWidth: 1,
                    fill: false
                }]
            },
            options: {
                maintainAspectRatio: false,
                legend: {
                    display: false,
                    labels: {
                        display: false
                    }
                },
                scales: {
                    yAxes: [{
                        gridLines: {
                            color: '#e5e9f2'
                        },
                        ticks: {
                            beginAtZero:true,
                            fontSize: 10,
                            max: 80
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            beginAtZero:true,
                            fontSize: 11
                        }
                    }]
                }
            }
        });
    });

    return (
        <div>
            <div className="content bd-b">
                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <div className="d-sm-flex align-items-center">
                        <div className="d-flex">
                            <img src={Logo} alt="logo" className="ht-60 mg-b-6"/>
                            <div className="mg-l-15 mg-t-5">
                                <h4 className="mg-b-5">Try Out</h4>
                                <p className="mg-b-0 tx-color-03">For testing purpose.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <div className="col-lg-12 mg-t-10">
                        <div className="card card-crypto">
                            <div className="card-header pd-y-8 d-sm-flex align-items-center justify-content-between">
                                <nav className="nav nav-line">
                                    <a href="" className="nav-link">Data: </a>
                                    <a href="" className="nav-link active">Day</a>
                                </nav>
                                <div className="tx-12 tx-color-03 align-items-center d-none d-sm-flex">
                                    <a href="" className="link-01 tx-spacing-1 tx-rubik">03/01/2019 <i className="icon ion-ios-arrow-down"></i></a>
                                    <span className="mg-x-10">to</span>
                                    <a href="" className="link-01 tx-spacing-1 tx-rubik">03/02/2019 <i className="icon ion-ios-arrow-down"></i></a>
                                </div>
                            </div>

                            <div className="card-body pd-10 pd-sm-20">
                                <div className="chart-eleven">
                                    {/*<div className="ht-250 ht-lg-300">*/}
                                    {/*    <canvas id="chartBar1"></canvas>*/}
                                    {/*</div>*/}
                                    <div className="ht-250 ht-lg-300">
                                        <canvas id="chartLine1"></canvas>
                                    </div>
                                </div>
                            </div>

                            <div className="card-footer pd-20">
                                <div className="row row-sm">
                                    <div className="col-6 col-sm-4 col-md">
                                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-03 mg-b-10">Market
                                            Cap</h6>
                                        <h5 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">$14.5B</h5>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md">
                                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-03 mg-b-10">Volume
                                            (24h)</h6>
                                        <h5 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">$4.6B</h5>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md mg-t-20 mg-sm-t-0">
                                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-03 mg-b-10">Change</h6>
                                        <h5 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">-$7.98 <small
                                            className="tx-danger"><i className="icon ion-md-arrow-down"></i></small>
                                        </h5>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl mg-t-20 mg-md-t-0">
                                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-03 mg-b-10"><span
                                            className="d-none d-sm-inline">Circulating </span>Supply</h6>
                                        <h5 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">17.59M</h5>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md mg-t-20 mg-md-t-0">
                                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-03 mg-b-10">All Time
                                            High</h6>
                                        <h5 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">$18.4K</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Try;
