const initialState = {
    ad_report_by_city: null,
    ad_report_by_suburb: null,
    chart_data: null,
    chart_type: "linechart",
    loading: false
};

export default function(state = initialState, action) {
    switch(action.type) {
        case 'AD_REPORT_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'REPORT_CHART_DATA':
            return {
                ...state,
                chart_data: action.payload,
                loading: false //Later
            };

        case 'CHART_TYPE':
            return {
                ...state,
                chart_type: action.payload
            };


        case 'AD_REPORT_CITY_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'GET_AD_REPORT_CITY':
            return {
                ...state,
                ad_report_by_city: action.payload,
                loading: false
            };
        case 'CLEAR_AD_REPORT_CITY':
            return {
                ...state,
                ad_report_by_city: null
            };

        case 'AD_REPORT_SUBURB_LOADING':
            return {
                ...state,
                loading: true
            };
        case 'GET_AD_REPORT_SUBURB':
            return {
                ...state,
                ad_report_by_suburb: action.payload,
                loading: false
            };
        case 'CLEAR_AD_REPORT_SUBURB':
            return {
                ...state,
                ad_report_by_suburb: null
            };
        default:
            return state;
    }
}
