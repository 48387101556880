const persistStorage = (type, key, data) => {

    if(data !== null && type === "object") {
        localStorage.setItem(key, data);
    }

    if(data == null && type === "object") {
        return localStorage.getItem(key);
    }

    if(data !== null && type === "json") {
        localStorage.setItem(key, JSON.stringify(data));
    }

    if(data == null && type === "json") {
        return JSON.parse(localStorage.getItem(key));
    }

    return null;

};

export default persistStorage;
