const initialState = {
    campaign_ad: null,
    loading: false
};

export default function(state = initialState, action) {
    switch(action.type) {
        case 'CAMPAIGN_AD_LOADING':
            return {
                ...state,
                loading: true
            };
        case 'GET_CAMPAIGN_AD':
            return {
                ...state,
                campaign_ad: action.payload,
                loading: false
            };
        case 'CLEAR_CAMPAIGN_AD':
            return {
                ...state,
                campaign_ad: null
            };
        default:
            return state;
    }
}
