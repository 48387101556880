import React from 'react';
import moment from "moment";
import {ColumnChart, PieChart} from "react-chartkick";
import NumberFormat from "react-number-format";
import {decodeQueryParameter} from "../../../utils/url";
import ClickType from "./generate-components/ClickType";

const Generate = (props) => {
    const ad_report = decodeQueryParameter(props.match.params.report.replace(/'/g, ""));
    const ad_data = decodeQueryParameter(props.match.params.ad.replace(/'/g, ""));
    const selected_start = decodeQueryParameter(props.match.params.start.replace(/'/g, ""));
    const selected_end = decodeQueryParameter(props.match.params.end.replace(/'/g, ""));

    const total_cost = (raw) => {
        const cpm_array = raw.cost_model.find((result) => {
            return result.cpm;
        });

        const cpc_array = raw.cost_model.find((result) => {
            return result.cpc;
        });

        const cpa_array = raw.cost_model.find((result) => {
            return result.cpa;
        });

        let total_cpm = 0;
        let total_cpc = 0;
        let total_cpa = 0;

        if(cpm_array !== undefined) {
            if(ad_data.custom_cost.type !== "premium") {
                total_cpm = cpm_array.cpm.reduce((acc, count) => acc + count, 0) * 0.20;
            } else {
                total_cpm = cpm_array.cpm.reduce((acc, count) => acc + count, 0) * 0.30;
            }
            // _total_cpm(cpm_array.cpm.reduce((acc, count) => acc + count, 0));
        }

        if(cpc_array !== undefined) {
            if(ad_data.custom_cost.type !== "premium") {
                total_cpc = cpc_array.cpc.reduce((acc, count) => acc + count, 0) * 0.30;
            } else {
                total_cpc = cpc_array.cpc.reduce((acc, count) => acc + count, 0) * 0.45;
            }
        }

        if(cpa_array !== undefined) {
            if(ad_data.custom_cost.type !== "premium") {
                total_cpa = cpa_array.cpa.reduce((acc, count) => acc + count, 0) * 0.50;
            } else {
                total_cpa = cpa_array.cpa.reduce((acc, count) => acc + count, 0) * 0.75;
            }
            // _total_cpa(cpm_array.cpm.reduce((acc, count) => acc + count, 0));
        }

        return total_cpm + total_cpc + total_cpa;
    };

    const chart_data = [
            {
                name: '18 - 24',
                data: {
                    'lockscreen': 589,
                    'home': 1830,
                    'viewed': 2277
                }
            },
            {
                name: '25 - 34',
                data: {
                    'lockscreen': 589,
                    'home': 1830,
                    'viewed': 2277
                }
            },
            {
                name: '34 - 44',
                data: {
                    'lockscreen': 589,
                    'home': 1830,
                    'viewed': 2277
                }
            },
            {
                name: '45 and above',
                data: {
                    'lockscreen': 589,
                    'home': 1830,
                    'viewed': 2277
                }
            }
        ];

    const male_pie_data = {
        "18 -24": ad_report.m_group_a,
        "25 - 34": ad_report.m_group_b,
        "35 - 44": ad_report.m_group_c,
        "45 and above": ad_report.m_group_d
    };

    const female_pie_data = {
        "18 -24": ad_report.f_group_a,
        "25 - 34": ad_report.f_group_b,
        "35 - 44": ad_report.f_group_c,
        "45 and above": ad_report.f_group_d,
    };

    const renderRegion = () => {
        return ad_report.region.map((result) => {
            let name = Object.getOwnPropertyNames(result);
            let total_clicks = result[name][0]+result[name][1]+result[name][2]+result[name][3]+result[name][4]+result[name][5]+result[name][6]+result[name][7];
            let total_male = result[name][0]+result[name][1]+result[name][2]+result[name][3];
            let total_female = result[name][4]+result[name][5]+result[name][6]+result[name][7];
            let renderRate = (initial) => {
                return initial/ad_report.total_clicks * 100;
            };
            return(
                <tr key={name}>
                    <td>{name}</td>
                    <td><NumberFormat value={total_clicks} thousandSeparator={true} displayType={'text'} decimalScale={0}/></td>
                    <td><NumberFormat value={renderRate(total_clicks, total_clicks).toFixed(2)+"%"} thousandSeparator={true} displayType={'text'} decimalScale={2}/></td>
                    <td><NumberFormat value={total_male} thousandSeparator={true} displayType={'text'} decimalScale={0}/></td>
                    <td><NumberFormat value={total_female} thousandSeparator={true} displayType={'text'} decimalScale={0}/></td>
                </tr>
            )
        });
    };

    return (
        <div className="container">
            <p className="text-center mg-t-30">
                <img src="https://adzlok.co.za/assets/img/logo/logo-icon.png" width="80" alt="AdzLok Logo"/>
            </p>
            <h5 className="text-center tx-15 mg-b-20">AdzLok Client Campaign Report<br/><span className="tx-15">{moment(ad_data.current_date).format("D MMMM, YYYY")}</span></h5>
            <h6>Client Name: {ad_data.name}</h6>

            <table className="table table-striped table-bordered">
                <thead>
                <tr>
                    <th className="wd-50p">Campaign Name</th>
                    <th className="wd-50p">{ad_data.name}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Total Duration of Campaign</td>
                    <td>{moment(ad_data.start_date).format("D MMMM, YYYY")} --- {moment(ad_data.end_date).format("D MMMM, YYYY")}</td>
                </tr>
                <tr>
                    <td>Period of Report</td>
                    <td>{moment(selected_start).format("D MMMM, YYYY")} --- {moment(selected_end).format("D MMMM, YYYY")}</td>
                </tr>
                <tr>
                    <td>Total Clicks (All Time) </td>
                    <td><NumberFormat value={ad_report.total_clicks} thousandSeparator={true} displayType={'text'} decimalScale={0}/></td>
                </tr>
                <tr>
                    <td>Clicks for Selected Period</td>
                    <td><NumberFormat value={ad_report.m_group_a+ad_report.m_group_b+ad_report.m_group_c+ad_report.m_group_d+ad_report.f_group_a+ad_report.f_group_b+ad_report.f_group_c+ad_report.f_group_d} thousandSeparator={true} displayType={'text'} decimalScale={0}/></td>
                </tr>
                <tr>
                    <td>Cost of Clicks for Selected Period</td>
                    <td><NumberFormat value={total_cost(ad_report)} thousandSeparator={true} displayType={'text'} decimalScale={2} prefix={'R'}/></td>
                </tr>
                </tbody>
            </table>

            <hr className="mg-b-30 mg-t-30"/>

            <h6>> Clicks Type</h6>

            <ClickType ad_report={ad_report.cost_model} cost_model={ad_data.cost_model}/>

            <hr className="mg-b-30 mg-t-30"/>

            <h6>> Clicks by Gender</h6>
            <table className="table table-bordered">
                <thead>
                <tr>
                    <th className="wd-25p">Age Group</th>
                    <th className="wd-25p">Male</th>
                    <th className="wd-25p">Female</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>18 - 24</td>
                    <td><NumberFormat value={ad_report.m_group_a} thousandSeparator={true} displayType={'text'} decimalScale={0}/></td>
                    <td><NumberFormat value={ad_report.f_group_a} thousandSeparator={true} displayType={'text'} decimalScale={0}/></td>
                </tr>
                <tr>
                    <td>25 - 34</td>
                    <td><NumberFormat value={ad_report.m_group_b} thousandSeparator={true} displayType={'text'} decimalScale={0}/></td>
                    <td><NumberFormat value={ad_report.f_group_b} thousandSeparator={true} displayType={'text'} decimalScale={0}/></td>
                </tr>
                <tr>
                    <td>35 - 44</td>
                    <td><NumberFormat value={ad_report.m_group_c} thousandSeparator={true} displayType={'text'} decimalScale={0}/></td>
                    <td><NumberFormat value={ad_report.f_group_c} thousandSeparator={true} displayType={'text'} decimalScale={0}/></td>
                </tr>
                <tr>
                    <td>45 and above</td>
                    <td><NumberFormat value={ad_report.m_group_d} thousandSeparator={true} displayType={'text'} decimalScale={0}/></td>
                    <td><NumberFormat value={ad_report.f_group_d} thousandSeparator={true} displayType={'text'} decimalScale={0}/></td>
                </tr>
                <tr>
                    <td className="tx-semibold">Total</td>
                    <td className="tx-semibold"><NumberFormat value={ad_report.m_group_a+ad_report.m_group_b+ad_report.m_group_c+ad_report.m_group_d} thousandSeparator={true} displayType={'text'} decimalScale={0}/></td>
                    <td className="tx-semibold"><NumberFormat value={ad_report.f_group_a+ad_report.f_group_b+ad_report.f_group_c+ad_report.f_group_d} thousandSeparator={true} displayType={'text'} decimalScale={0}/></td>
                </tr>
                </tbody>
            </table>

            <hr className="mg-b-30 mg-t-30"/>

            <h6>> Clicks Action Area</h6>
            <table className="table table-striped table-bordered">
                <thead>
                <tr>
                    <th className="wd-25p">Lockscreen</th>
                    <th className="wd-25p">HomeTab in-app</th>
                    <th className="wd-25p">ViewedTab in-app</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td><NumberFormat value={ad_report.total_lockscreen} thousandSeparator={true} displayType={'text'} decimalScale={0}/></td>
                    <td><NumberFormat value={ad_report.total_home} thousandSeparator={true} displayType={'text'} decimalScale={0}/></td>
                    <td><NumberFormat value={ad_report.total_viewed} thousandSeparator={true} displayType={'text'} decimalScale={0}/></td>
                </tr>
                </tbody>
            </table>

            <hr className="mg-b-30 mg-t-30"/>

            <h6>> Campaign Clicks by Provinces</h6>
            <table className="table table-striped table-bordered">
                <thead>
                <tr>
                    <th className="wd-30p">Regions</th>
                    <th className="wd-15p">Clicks</th>
                    <th className="wd-15p">Hit Rate(%)</th>
                    <th className="wd-15p">Male</th>
                    <th className="wd-15p">Female</th>
                </tr>
                </thead>
                <tbody>
                {renderRegion()}
                </tbody>
            </table>

            {/*<hr className="mg-b-30 mg-t-30"/>*/}

            {/*<h6>> Campaign Clicks by City</h6>*/}
            {/*<table className="table table-striped table-bordered">*/}
            {/*    <thead>*/}
            {/*    <tr>*/}
            {/*        <th className="wd-30p"><i className="fas fa-globe-africa"/> City</th>*/}
            {/*        <th className="wd-15p"><i className="fas fa-hand-point-up"/> Clicks</th>*/}
            {/*        <th className="wd-15p"><i className="fas fa-bullhorn"/> Hit Rate(%)</th>*/}
            {/*        <th className="wd-15p"><i className="fas fa-mars"/> Male</th>*/}
            {/*        <th className="wd-15p"><i className="fas fa-venus"/> Female</th>*/}
            {/*    </tr>*/}
            {/*    </thead>*/}
            {/*    <tbody>*/}
            {/*    <tr>*/}
            {/*        <td>Soweto</td>*/}
            {/*        <td>38,874</td>*/}
            {/*        <td>70%</td>*/}
            {/*        <td>26,433</td>*/}
            {/*        <td>12,441</td>*/}
            {/*    </tr>*/}
            {/*    <tr>*/}
            {/*        <td>Pretoria</td>*/}
            {/*        <td>4,359</td>*/}
            {/*        <td>7.85%</td>*/}
            {/*        <td>4,051</td>*/}
            {/*        <td>2,008</td>*/}
            {/*    </tr>*/}
            {/*    <tr>*/}
            {/*        <td>Sandton</td>*/}
            {/*        <td>3,261</td>*/}
            {/*        <td>5.87%</td>*/}
            {/*        <td>2,783</td>*/}
            {/*        <td>1,478</td>*/}
            {/*    </tr>*/}
            {/*    </tbody>*/}
            {/*</table>*/}

            {/*<hr className="mg-b-30 mg-t-30"/>*/}

            {/*<h6>> Campaign Clicks by Suburb</h6>*/}
            {/*<table className="table table-striped table-bordered">*/}
            {/*    <thead>*/}
            {/*    <tr>*/}
            {/*        <th className="wd-30p"><i className="fas fa-globe-africa"/> Regions</th>*/}
            {/*        <th className="wd-15p"><i className="fas fa-hand-point-up"/> Clicks</th>*/}
            {/*        <th className="wd-15p"><i className="fas fa-bullhorn"/> Hit Rate(%)</th>*/}
            {/*        <th className="wd-15p"><i className="fas fa-mars"/> Male</th>*/}
            {/*        <th className="wd-15p"><i className="fas fa-venus"/> Female</th>*/}
            {/*    </tr>*/}
            {/*    </thead>*/}
            {/*    <tbody>*/}
            {/*    <tr>*/}
            {/*        <td>Alexandra</td>*/}
            {/*        <td>38,874</td>*/}
            {/*        <td>70%</td>*/}
            {/*        <td>26,433</td>*/}
            {/*        <td>12,441</td>*/}
            {/*    </tr>*/}
            {/*    <tr>*/}
            {/*        <td>Wendywood</td>*/}
            {/*        <td>4,359</td>*/}
            {/*        <td>7.85%</td>*/}
            {/*        <td>4,051</td>*/}
            {/*        <td>2,008</td>*/}
            {/*    </tr>*/}
            {/*    <tr>*/}
            {/*        <td>Kelvin</td>*/}
            {/*        <td>3,261</td>*/}
            {/*        <td>5.87%</td>*/}
            {/*        <td>2,783</td>*/}
            {/*        <td>1,478</td>*/}
            {/*    </tr>*/}
            {/*    </tbody>*/}
            {/*</table>*/}

            <hr className="mg-b-30 mg-t-30"/>

            <h6>> Clicks by Age and Actioned Area (Male)</h6>

            <div className="row">
                <div className="col-md-6">
                    <ColumnChart
                        data={chart_data}
                        download={true}
                        legend="bottom"
                        colors={["#5C9E33", "#317EE2", "#da6a38"]}
                        messages={{empty: "No data Available"}}
                        dataset={{borderWidth: 1}}
                    />
                </div>

                <div className="col-md-6">
                    <PieChart
                        data={male_pie_data}
                        download={true}
                        legend="bottom"
                        colors={["#5C9E33", "#317EE2", "#da6a38"]}
                        messages={{empty: "No data Available"}}
                        dataset={{borderWidth: 1}}
                    />
                </div>
            </div>

            {/*<hr className="mg-b-30 mg-t-30"/>*/}

            {/*<h6>> Clicks by Age and Actioned Area (Female)</h6>*/}

            {/*<div className="row">*/}
            {/*    <div className="col-md-6">*/}
            {/*        <ColumnChart*/}
            {/*            data={chart_data}*/}
            {/*            download={true}*/}
            {/*            legend="bottom"*/}
            {/*            colors={["#5C9E33", "#317EE2", "#da6a38"]}*/}
            {/*            messages={{empty: "No data Available"}}*/}
            {/*            dataset={{borderWidth: 1}}*/}
            {/*        />*/}
            {/*    </div>*/}

            {/*    <div className="col-md-6">*/}
            {/*        <PieChart*/}
            {/*            data={female_pie_data}*/}
            {/*            download={true}*/}
            {/*            legend="bottom"*/}
            {/*            colors={["#5C9E33", "#317EE2", "#da6a38"]}*/}
            {/*            messages={{empty: "No data Available"}}*/}
            {/*            dataset={{borderWidth: 1}}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="mg-t-40 text-center">End of Report</div>

            <div className="text-center tx-10 mg-b-15">Report Generated by Adzlok</div>
        </div>
    )
};

export default Generate;
