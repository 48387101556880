import axios from 'axios';
import { GET_CAMPAIGN_AD_URL, GET_AD_REPORT_BY_CITY, GET_AD_REPORT_BY_SUBURB } from '../../api';

export const getCampaignAd = (id) => {
    return function(dispatch) {
        dispatch(setCampaignAdLoading());
        return axios.post(GET_CAMPAIGN_AD_URL, {id}).then((response) => {
            dispatch({
                type: 'GET_CAMPAIGN_AD',
                payload: response.data
            })
        }).catch((err) => {
            dispatch({
                type: 'GET_NO_CAMPAIGN_AD',
                payload: {}
            })
        });
    }
};

export const getAdReportByCity = (id, start_date, end_date, region) => {
    return function(dispatch) {
        dispatch(setAdReportByCityLoading());
        return axios.post(GET_AD_REPORT_BY_CITY, {id, start_date, end_date, region}).then((response) => {
            dispatch({
                type: 'GET_AD_REPORT_CITY',
                payload: response.data
            })
        }).catch(() => {
            dispatch({
                type: 'GET_NO_AD_REPORT_CITY',
                payload: {}
            })
        });
    }
};

export const setLoading = () => {
    console.log("Passed");
};

export const getAdReportBySuburb = (id, start_date, end_date, city) => {
    return function(dispatch) {
        dispatch(setAdReportBySuburbLoading());
        return axios.post(GET_AD_REPORT_BY_SUBURB, {id, start_date, end_date, city}).then((response) => {
            dispatch({
                type: 'GET_AD_REPORT_SUBURB',
                payload: response.data
            })
        }).catch(() => {
            dispatch({
                type: 'GET_NO_AD_REPORT_SUBURB',
                payload: {}
            })
        });
    }
};

// Campaign Loading
export const setCampaignAdLoading = () => {
    return {
        type: 'CAMPAIGN_AD_LOADING'
    }
};

// Ad Report Loading
export const setAdReportByCityLoading = () => {
    return {
        type: 'AD_REPORT_CITY_LOADING'
    }
};

// Ad Report Loading
export const setAdReportBySuburbLoading = () => {
    return {
        type: 'AD_REPORT_SUBURB_LOADING'
    }
};
