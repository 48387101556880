import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';
import {save, load} from "redux-localstorage-simple";
import { composeWithDevTools } from 'redux-devtools-extension';

const initialState = {};

const store = createStore(rootReducer, load(), compose(
    compose(
        composeWithDevTools(applyMiddleware(
            thunk,
            thunkMiddleware,
            save()
        ))
    )
));

export default store;
