import React from 'react';
import PageError from "../../components/layout/PageError";
import CreateAd from '../../components/create-campaign/CreateAd';
import CampaignAd from "../../components/dashboard/CampaignAd";
import AdReport from "../../components/dashboard/AdReport";
import Account from '../../components/Profile/Account';
import City from '../../components/dashboard/report/City';
import _City from '../../components/dashboard/user/City';
import _Suburb from '../../components/dashboard/user/Suburb';
import Suburb from '../../components/dashboard/report/Suburb';
import Generate from "../../components/dashboard/report/Generate";
import PrivateRoute from '../../components/common/PrivateRoute';
import InvoiceReport from '../../components/dashboard/InvoiceReport';
import Dashboard from '../../components/dashboard/Dashboard';
import Invoice from '../../components/dashboard/Invoice';
import {Switch, Route} from 'react-router-dom';
import Statistics from "../dashboard/Statistics";
import Wallet from "../Profile/Wallet";

const Private = () => {
    return (
        <Switch>
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/statistics" component={Statistics}/>
            <PrivateRoute exact path="/city-statistics/:name" component={_City}/>
            <PrivateRoute exact path="/suburb-statistics/:name" component={_Suburb}/>
            <PrivateRoute exact path="/invoice" component={Invoice}/>
            <PrivateRoute exact path="/invoice-report" component={InvoiceReport}/>
            <PrivateRoute exact path="/campaign-ads/:id/:name" component={CampaignAd}/>
            <PrivateRoute exact path="/ad-report/:ad" component={AdReport}/>
            <PrivateRoute exact path="/ad-city/:id/:name/:data" component={City}/>
            <PrivateRoute exact path="/ad-suburb/:data" component={Suburb}/>
            <PrivateRoute exact path="/ad-generate-report/:ad/:report/:start/:end" component={Generate}/>
            <PrivateRoute exact path="/create-ads" component={CreateAd}/>
            <PrivateRoute exact path="/account" component={Account}/>
            <PrivateRoute exact path="/wallet" component={Wallet}/>
            <Route component={PageError} />
        </Switch>
    )
};

export default Private;
