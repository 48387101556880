const initialState = {
    invoice: null,
    invoice_data: null,
    loading: false
};

export default function(state = initialState, action) {
    switch(action.type) {
        case 'INVOICE_LOADING':
            return {
                ...state,
                loading: true
            };
        case 'SET_INVOICE':
            return {
                ...state,
                invoice_data: action.payload
            };
        case 'GET_INVOICE':
            return {
                ...state,
                invoice: action.payload,
                loading: false
            };
        case 'CLEAR_INVOICE':
            return {
                ...state,
                invoice: null
            };
        default:
            return state;
    }
}
