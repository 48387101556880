const initialState = {};

export default function(state = initialState, action) {
    switch(action.type) {
        case 'ROUTE_CITY':
            return {
                ...state,
                city: action.city
            };
        default:
            return state;
    }
}
