import React, { Component } from 'react';
import formatNumber from "../../../../utils/formatNumber";

class ClickType extends Component {

    render() {
        switch (this.props.cost_model) {
            case "cpc":
                const cpm_cpc_array = this.props.ad_report.find((result) => {
                    return result.cpm;
                });

                const cpc_array = this.props.ad_report.find((result) => {
                    return result.cpc;
                });

                return (
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th colSpan="2">CPM</th>
                            <th colSpan="2">CPC</th>
                        </tr>
                        <tr>
                            <th>Age</th>

                            <th>Male</th>
                            <th>Female</th>

                            <th>Male</th>
                            <th>Female</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>18 - 24</td>
                            <td><strong>{formatNumber(cpm_cpc_array.cpm[0])}</strong></td>
                            <td><strong>{formatNumber(cpm_cpc_array.cpm[4])}</strong></td>
                            <td><strong>{formatNumber(cpc_array.cpc[0])}</strong></td>
                            <td><strong>{formatNumber(cpc_array.cpc[4])}</strong></td>
                        </tr>
                        <tr>
                            <td>25 - 34</td>
                            <td><strong>{formatNumber(cpm_cpc_array.cpm[1])}</strong></td>
                            <td><strong>{formatNumber(cpm_cpc_array.cpm[5])}</strong></td>
                            <td><strong>{formatNumber(cpc_array.cpc[1])}</strong></td>
                            <td><strong>{formatNumber(cpc_array.cpc[5])}</strong></td>
                        </tr>
                        <tr>
                            <td>35 - 44</td>
                            <td><strong>{formatNumber(cpm_cpc_array.cpm[2])}</strong></td>
                            <td><strong>{formatNumber(cpm_cpc_array.cpm[6])}</strong></td>
                            <td><strong>{formatNumber(cpc_array.cpc[2])}</strong></td>
                            <td><strong>{formatNumber(cpc_array.cpc[6])}</strong></td>
                        </tr>
                        <tr>
                            <td>45 and above</td>
                            <td><strong>{formatNumber(cpm_cpc_array.cpm[3])}</strong></td>
                            <td><strong>{formatNumber(cpm_cpc_array.cpm[7])}</strong></td>
                            <td><strong>{formatNumber(cpc_array.cpc[3])}</strong></td>
                            <td><strong>{formatNumber(cpc_array.cpc[7])}</strong></td>
                        </tr>
                        <tr>
                            <td className="tx-semibold">Total</td>
                            <td className="tx-semibold"><strong>{formatNumber(cpm_cpc_array.cpm[0]+cpm_cpc_array.cpm[1]+cpm_cpc_array.cpm[2]+cpm_cpc_array.cpm[3])}</strong></td>
                            <td className="tx-semibold"><strong>{formatNumber(cpm_cpc_array.cpm[4]+cpm_cpc_array.cpm[5]+cpm_cpc_array.cpm[6]+cpm_cpc_array.cpm[7])}</strong></td>
                            <td className="tx-semibold"><strong>{formatNumber(cpc_array.cpc[0]+cpc_array.cpc[1]+cpc_array.cpc[2]+cpc_array.cpc[3])}</strong></td>
                            <td className="tx-semibold"><strong>{formatNumber(cpc_array.cpc[4]+cpc_array.cpc[5]+cpc_array.cpc[6]+cpc_array.cpc[7])}</strong></td>
                        </tr>
                        </tbody>
                    </table>
                );

            case "cpa":
                const cpm_cpa_array = this.props.ad_report.find((result) => {
                    return result.cpm;
                });

                const cpa_array = this.props.ad_report.find((result) => {
                    return result.cpa;
                });

                return (
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th colSpan="2">CPM</th>
                            <th colSpan="2">CPA</th>
                        </tr>
                        <tr>
                            <th>Age</th>

                            <th>Male</th>
                            <th>Female</th>

                            <th>Male</th>
                            <th>Female</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>18 - 24</td>
                            <td><strong>{formatNumber(cpm_cpa_array.cpm[0])}</strong></td>
                            <td><strong>{formatNumber(cpm_cpa_array.cpm[4])}</strong></td>
                            <td><strong>{formatNumber(cpa_array.cpa[0])}</strong></td>
                            <td><strong>{formatNumber(cpa_array.cpa[4])}</strong></td>
                        </tr>
                        <tr>
                            <td>25 - 34</td>
                            <td><strong>{formatNumber(cpm_cpa_array.cpm[1])}</strong></td>
                            <td><strong>{formatNumber(cpm_cpa_array.cpm[5])}</strong></td>
                            <td><strong>{formatNumber(cpa_array.cpa[1])}</strong></td>
                            <td><strong>{formatNumber(cpa_array.cpa[5])}</strong></td>
                        </tr>
                        <tr>
                            <td>35 - 44</td>
                            <td><strong>{formatNumber(cpm_cpa_array.cpm[2])}</strong></td>
                            <td><strong>{formatNumber(cpm_cpa_array.cpm[6])}</strong></td>
                            <td><strong>{formatNumber(cpa_array.cpa[2])}</strong></td>
                            <td><strong>{formatNumber(cpa_array.cpa[6])}</strong></td>
                        </tr>
                        <tr>
                            <td>45 and above</td>
                            <td><strong>{formatNumber(cpm_cpa_array.cpm[3])}</strong></td>
                            <td><strong>{formatNumber(cpm_cpa_array.cpm[7])}</strong></td>
                            <td><strong>{formatNumber(cpa_array.cpa[3])}</strong></td>
                            <td><strong>{formatNumber(cpa_array.cpa[7])}</strong></td>
                        </tr>
                        <tr>
                            <td className="tx-semibold">Total</td>
                            <td className="tx-semibold"><strong>{formatNumber(cpm_cpa_array.cpa[0]+cpm_cpa_array.cpa[1]+cpm_cpa_array.cpa[2]+cpm_cpa_array.cpa[3])}</strong></td>
                            <td className="tx-semibold"><strong>{formatNumber(cpm_cpa_array.cpa[4]+cpm_cpa_array.cpa[5]+cpm_cpa_array.cpa[6]+cpm_cpa_array.cpa[7])}</strong></td>
                            <td className="tx-semibold"><strong>{formatNumber(cpa_array.cpa[0]+cpa_array.cpa[1]+cpa_array.cpa[2]+cpa_array.cpa[3])}</strong></td>
                            <td className="tx-semibold"><strong>{formatNumber(cpa_array.cpa[4]+cpa_array.cpa[5]+cpa_array.cpa[6]+cpa_array.cpa[7])}</strong></td>
                        </tr>
                        </tbody>
                    </table>
                );
            default:
                const cpm_array = this.props.ad_report.find((result) => {
                    return result.cpm;
                });

                return (
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Age Group</th>
                            <th colSpan="2">CPM</th>
                        </tr>
                        <tr>
                            <th>Age</th>

                            <th>Male</th>
                            <th>Female</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>18 - 24</td>
                            <td><strong>{formatNumber(cpm_array.cpm[0])}</strong></td>
                            <td><strong>{formatNumber(cpm_array.cpm[4])}</strong></td>
                        </tr>
                        <tr>
                            <td>25 - 34</td>
                            <td><strong>{formatNumber(cpm_array.cpm[1])}</strong></td>
                            <td><strong>{formatNumber(cpm_array.cpm[5])}</strong></td>
                        </tr>
                        <tr>
                            <td>35 - 44</td>
                            <td><strong>{formatNumber(cpm_array.cpm[2])}</strong></td>
                            <td><strong>{formatNumber(cpm_array.cpm[6])}</strong></td>
                        </tr>
                        <tr>
                            <td>45 and above</td>
                            <td><strong>{formatNumber(cpm_array.cpm[3])}</strong></td>
                            <td><strong>{formatNumber(cpm_array.cpm[7])}</strong></td>
                        </tr>
                        <tr>
                            <td className="tx-semibold">Total</td>
                            <td className="tx-semibold"><strong>{formatNumber(cpm_array.cpm[0]+cpm_array.cpm[1]+cpm_array.cpm[2]+cpm_array.cpm[3])}</strong></td>
                            <td className="tx-semibold"><strong>{formatNumber(cpm_array.cpm[4]+cpm_array.cpm[5]+cpm_array.cpm[6]+cpm_array.cpm[7])}</strong></td>
                        </tr>
                        </tbody>
                    </table>
                );
        }
    }
}

export default ClickType;
