import React, { useEffect, useState } from 'react';
import Header from '../layout/Header';
import {GET_ACCOUNT_CREDIT} from '../../api';
import {useDispatch, useSelector} from 'react-redux';
import $ from 'jquery';
import {Link} from "react-router-dom";
import 'cleave.js/dist/addons/cleave-phone.i18n';
import Logo from "../../assets/img/invoice-logo.png";
import formatNumber from "../../utils/formatNumber";
import deposit from "../../utils/deposit";
import {getProfile} from "../../redux/actions";

const Wallet = () => {

    document.title = "Wallet";
    const token = localStorage.getItem('jwtToken');
    const auth = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    const [amount, setAmount] = useState("");
    const [disabled, _disabled] = useState("");

    useEffect(() => {
        dispatch(getProfile());
        $(function () {
            $('#credit_').DataTable({
                responsive: true,
                "language": {
                    "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Credit Report</p>'
                },
                // "searching": true,
                "lengthMenu": [[10], [10]],
                ordering: false,
                info: true,
                bFilter: false,
                processing: true,
                pageLength: 10,
                serverSide: true,
                ajax: function(data, callback) {
                    // make a regular ajax request using data.start and data.length
                    $.ajax(GET_ACCOUNT_CREDIT, {
                        type: 'GET',
                        headers: {
                            "Authorization": token
                        },
                        data: {
                            query: $('.dataTables_filter input').val(),
                            pageSize: data.start,
                            format: 'json',
                            pageIndex: (data.length + data.start) / data.length
                        },
                        success : function(res) {
                            callback({
                                recordsTotal: res.total,
                                recordsFiltered: res.total,
                                data: res.data
                            });
                        }
                    });
                },
                "columns": [
                    {"data": "name"},
                    {"data": "description"},
                    {"data": "amount"},
                    {"data": "status"},
                    {"data": "date_created"}
                ],
                "columnDefs": [
                    {
                        "render": function (data) {
                            return data;
                        },
                        "targets": 0
                    },
                    {
                        "render": function (data) {
                            return data;
                        },
                        "targets": 1
                    },
                    {
                        "render": function (data) {
                            return `R${formatNumber(data)}`;
                        },
                        "targets": 2
                    },
                    {
                        "render": function (data) {
                            return data;
                        },
                        "targets": 3
                    },
                    {
                        "render": function (data) {
                            return data;
                        },
                        "targets": 4
                    }
                ]
            });

            $('#_basic').parsley();

            $("#_basic").on('submit', function(e){
                e.preventDefault();
            });
        });

        return () => {
            $('#_basic').parsley().destroy();
        }

    }, []);

    const handleModal = () => {
        $("#modalCreateAd").modal("show");
    };

    const handleDeposit = () => {
        if(
            $('#amount').parsley().isValid() === false
        ) {
            return null;
        }
        _disabled("disabled");
        deposit("Deposit Fund", amount, auth.sub, auth.business_name);
    };

    const handleAmountChange = (event) => {
        setAmount(event.target.value);
    };

    return (
        <div>
            <Header />
            <div className="content content-fixed">
                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Wallet</li>
                                </ol>
                            </nav>
                            <h4 className="mg-b-0 tx-spacing--1">Your Wallet</h4>
                        </div>
                    </div>
                    <div className="card card-body">
                        <div className="d-md-flex align-items-center justify-content-between">
                            <div className="media align-sm-items-center">
                                <div className="tx-40 tx-lg-60 lh-0 tx-orange"><img src={Logo} height={60} alt=""/></div>
                                <div className="media-body mg-l-15">
                                    <h6 className="tx-12 tx-lg-14 tx-medium tx-uppercase tx-spacing-1 mg-b-5">Credit Balance</h6>
                                    <div className="d-flex align-items-baseline">
                                        <h2 className="tx-20 tx-lg-28 tx-normal tx-rubik tx-spacing--2 lh-2 mg-b-0">R{formatNumber(auth.wallet)}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-sm-row mg-t-20 mg-md-t-0">
                                <button onClick={handleModal.bind()} className="btn btn-sm btn-primary btn-uppercase pd-x-15 mg-t-5 mg-sm-t-0 mg-sm-l-5">
                                    <i className="ion-md-card"/> Deposit Fund
                                </button>
                            </div>
                        </div>
                    </div>
                    <div data-label="History" className="df-example demo-table mg-t-20">
                        <table id="credit_" className="table">
                            <thead>
                            <tr>
                                <th className="wd-20p">Name</th>
                                <th className="wd-15p">Description</th>
                                <th className="wd-10p">Amount</th>
                                <th className="wd-15p">Status</th>
                                <th className="wd-15p">Date</th>
                            </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modalCreateAd" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                    <div className="modal-content">
                        <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                            <div className="media align-items-center">
                                <div className="media-body">
                                    <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <h4 className="tx-18 tx-sm-20 mg-b-2">Deposit Fund</h4>
                                    <p className="tx-13 tx-color-02 mg-b-0">Enter amount to deposit in your credit balance</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                            <form id="_basic" className="parsley-style-1">
                                <div id="adWrapper" className="form-group parsley-input">
                                    <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Amount</label>
                                    <input id="amount" type="text" className="form-control"
                                           placeholder="Enter amount between 200 and above"
                                           value={amount}
                                           min={200}
                                           onChange={handleAmountChange.bind()}
                                           autoComplete="off"
                                           data-parsley-class-handler="#adWrapper" required/>
                                </div>
                                <button className="btn btn-brand-01 btn-block" onClick={handleDeposit.bind()} disabled={disabled}>Make Payment <i data-feather="check-circle"/></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Wallet;
