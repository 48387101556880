// const API_URL = 'https://dash.server.adzlok.co.za/v1/advertiser';
// const API_URL = 'https://dash.live.adzlok.co.za/v1/advertiser';
const API_URL = 'https://api.business.adzlok.africa/v1/advertiser';
// const API_URL = 'http://127.0.0.1:9001/v1/advertiser';

exports.SIGNIN_URL = `${API_URL}/signin`;
exports.SIGNUP_URL = `${API_URL}/signup`;
exports.GET_CAMPAIGN_URL = `${API_URL}/campaigns`;
exports.GET_INTEREST_URL = `${API_URL}/get-interest`;
exports.GET_CAMPAIGN_AD_URL = `${API_URL}/campaign-ads`;
exports.GET_CAMPAIGN_LIST = `${API_URL}/get-campaigns`;
exports.GET_AD_REPORT_BY_CITY = `${API_URL}/ad-report-city`;
exports.GET_AD_REPORT_BY_SUBURB = `${API_URL}/ad-report-suburb`;
exports.GET_AD_REPORT_URL = `${API_URL}/ad-report`;
exports.GET_CAMPAIGN_REPORT_URL = `${API_URL}/campaignReport`;
exports.GET_TOTAL_USER_REPORT_URL = `${API_URL}/get-total-user`;
exports.GET_REGIONAL_STATISTICS = `${API_URL}/get-regional-statistics`;
exports.GET_CITY_STATISTICS = `${API_URL}/get-city-statistics`;
exports.GET_SUBURB_STATISTICS = `${API_URL}/get-suburb-statistics`;
exports.GET_ACCOUNT_CREDIT = `${API_URL}/get-account-credit`;
exports.GET_INVOICE_URL = `${API_URL}/invoice`;
exports.GET_WALLET_URL = `${API_URL}/getWallet`;
exports.FORGOT_PASSWORD_URL = `${API_URL}/forgot-password`;
exports.RESET_PASSWORD_URL = `${API_URL}/reset-password`;
exports.VERIFY_PASSWORD_LINK_URL = `${API_URL}/verify-password-link`;
exports.VERIFY_GENERATED_LINK_URL = `${API_URL}/verify-link`;
exports.UPLOAD_CAMPAIGN_URL = `${API_URL}/create-campaign`;
exports.DELETE_CAMPAIGN_URL = `${API_URL}/delete-campaign`;
exports.UPATE_PASSWORD_URL = `${API_URL}/update-password`;
exports.UPATE_PROFILE_URL = `${API_URL}/update-profile`;
exports.GET_PROFILE_URL = `${API_URL}/get-profile`;
exports.GET_COUNTRY = `${API_URL}/get-country`;
exports.GET_PROVINCES = `${API_URL}/getProvinces`;
exports.GET_REGION_DATA = `${API_URL}/getRegions`;
exports.UPDATE_NEW_PASSWORD_URL = (token) => `${API_URL}/reset-password/token/${token}`;
