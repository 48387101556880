import React, {useEffect} from 'react';
import Header from '../layout/Header';
import Region from "./user/Region";
import NumberFormat from "react-number-format";
import {getTotalUser} from "../../redux/actions";
import {useDispatch, useSelector} from "react-redux";

const Statistics = () => {

    const dispatch = useDispatch();
    const user_report = useSelector((state) => state.report.user_report);
    document.title = "Statistics";

    useEffect(() => {
        dispatch(getTotalUser());
    }, []);

    return (
        <div>
            <Header />
            <div className="content content-fixed">
                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Statistics</li>
                                </ol>
                            </nav>
                            <h4 className="mg-b-0 tx-spacing--1">User Statistics</h4>
                        </div>
                    </div>
                    <div className="row row-xs">
                        <div className="col-lg-6 col-md-6">
                            <div className="card">
                                <div className="card-body pd-y-20 pd-x-25">
                                    <div className="row row-sm">
                                        <div className="col-12">
                                            <h3 className="tx-normal tx-rubik tx-spacing--1 mg-b-5"><NumberFormat value={user_report.active} thousandSeparator={true} displayType={'text'}/></h3>
                                            <h6 className="tx-12 tx-semibold tx-uppercase tx-spacing-1 tx-primary mg-b-5">Total Active Users</h6>
                                            <p className="tx-11 tx-color-03 mg-b-0">Number of users currently active.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="card">
                                <div className="card-body pd-y-20 pd-x-25">
                                    <div className="row row-sm">
                                        <div className="col-12">
                                            <h3 className="tx-normal tx-rubik tx-spacing--1 mg-b-5"><NumberFormat value={user_report.total} thousandSeparator={true} displayType={'text'}/></h3>
                                            <h6 className="tx-12 tx-semibold tx-uppercase tx-spacing-1 tx-primary mg-b-5">Total Updated Users</h6>
                                            <p className="tx-11 tx-color-03 mg-b-0">Total Database users</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Region/>
                </div>
            </div>
        </div>
    )
};

export default Statistics;
