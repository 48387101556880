import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link } from 'react-router-dom';
import Header from "../../layout/Header";
import {getAdReportBySuburb} from '../../../redux/actions/campaignAdActions';
import persistStorage from "../../../utils/persistStorage";
import $ from "jquery";
import formatNumber from "../../../utils/formatNumber";
import {decodeQueryParameter} from "../../../utils/url";

const SuburbList = (props) => {

    useEffect(() => {
        $('#suburb_').DataTable({
            responsive: true,
            pageLength: 25,
            order: [[ 1, "desc" ]],
            language: {
                searchPlaceholder: 'Search...',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
            }
        });
    }, []);

    const suburb = () => props.ad_suburb.data.map((result, index) => {
        let name = Object.getOwnPropertyNames(result);
        let renderRate = (initial) => {
            return initial/props.total * 100;
        };

        let total = result[name][0]+result[name][1]+result[name][2]+result[name][3]+result[name][4]+result[name][5]+result[name][6]+result[name][7];
        let t_male = result[name][0]+result[name][1]+result[name][2]+result[name][3];
        let t_female = result[name][4]+result[name][5]+result[name][6]+result[name][7];

        return(
            <tr key={name}>
                <td>{name}</td>
                <td>{total}</td>
                <td>{renderRate(total).toFixed(2)}%</td>
                <td>{t_male}</td>
                <td>{t_female}</td>
                <td>
                    <nav className="nav nav-icon-only">
                        <a href="#" data-toggle="modal" data-target={"#_"+index} className="nav-link outline-none"><i className="fa fa-venus-mars"/> Age</a></nav>
                    <div className="modal fade" id={"_"+index} tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="media align-items-center">
                                        <div className="media-body">
                                            <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </a>
                                            <p className="tx-13 tx-color-02 mg-b-0 tx-semibold">{name} - Age Group</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <table id="geography" className="table tx-13 mg-b-0">
                                        <thead>
                                        <tr>
                                            <th>Age Group</th>
                                            <th>Male</th>
                                            <th className="text-right">Female</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>18 - 24</td>
                                            <td>{formatNumber(result[name][0])}</td>
                                            <td className="text-right">{formatNumber(result[name][4])}</td>
                                        </tr>
                                        <tr>
                                            <td>25 - 34</td>
                                            <td>{formatNumber(result[name][1])}</td>
                                            <td className="text-right">{formatNumber(result[name][5])}</td>
                                        </tr>
                                        <tr>
                                            <td>35 - 44</td>
                                            <td>{formatNumber(result[name][2])}</td>
                                            <td className="text-right">{formatNumber(result[name][6])}</td>
                                        </tr>
                                        <tr>
                                            <td>45 and above</td>
                                            <td>{formatNumber(result[name][3])}</td>
                                            <td className="text-right">{formatNumber(result[name][7])}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        );

    });

    return(
        <div data-label="Campaign Clicks by City Geographic Targeting" className="df-example demo-table mg-t-20">
            <table id="suburb_" className="table">
                <thead>
                <tr>
                    <th className="wd-20p">Suburb</th>
                    <th className="wd-15p">Clicks</th>
                    <th className="wd-10p">Hit Rate</th>
                    <th className="wd-15p">Male</th>
                    <th className="wd-15p">Female</th>
                    <td className="wd-15p">Actions</td>
                </tr>
                </thead>
                <tbody>
                {suburb()}
                </tbody>
            </table>
        </div>
    );
};

const Suburb = (props) => {

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.ad_report.loading);
    const ad_suburb = useSelector((state) => state.ad_report.ad_report_by_suburb);

    const {id, name, total} = persistStorage("json", "suburb_route", null);

    const ad_data = decodeQueryParameter(props.match.params.ad.replace(/'/g, ""));

    const data = {
        id: id,
        start_date: "2019/12/10 00:00:00",
        end_date: "2019/12/15 23:59:59",
        city: name
    };

    useEffect(() => {
        dispatch(getAdReportBySuburb(data.id, data.start_date, data.end_date, data.city));
    }, []);

    let contentData;

    if(ad_suburb === null || loading) {
        contentData = (
            <div className="content content-fixed content-auth-alt">
                <div className="container d-flex justify-content-center ht-100p">
                    <div className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <p className="mg-t-10">Collecting Suburb Report</p>
                    </div>
                </div>
            </div>
        )
    } else {
        contentData =
            <div className="content content-fixed">
                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item">Dashboard</li>
                                    <li className="breadcrumb-item"><Link to="/campaign-ads">Campaign</Link></li>
                                    <li className="breadcrumb-item"><Link to="/ad-report">Ad Report</Link></li>
                                    <li className="breadcrumb-item"><Link to="/ad-city">City</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Suburb</li>
                                </ol>
                            </nav>
                            <h4 className="mg-b-0 tx-spacing--1">{name}</h4>
                        </div>
                    </div>
                    <SuburbList ad_suburb={ad_suburb} total={total}/>
                </div>
            </div>
    }

    return (
        <div>
            <Header />
            {contentData}
        </div>
    )
};


export default Suburb;
