const initialState = {
    campaign_report: null,
    loading: false,
    user_report: {
        total: 0,
        active: 0
    }
};

export default function(state = initialState, action) {
    switch(action.type) {
        case 'CAMPAIGN_REPORT_LOADING':
            return {
                ...state,
                loading: true
            };
        case 'GET_CAMPAIGN_REPORT':
            return {
                ...state,
                campaign_report: action.payload,
                loading: false
            };

        case 'GET_TOTAL_USER':
            return {
                ...state,
                user_report: action.payload
            };

        case 'GENERATE_REPORT':
            return {
                ...state,
                generated_report: action.payload,
            };
        case 'CLEAR_CAMPAIGN_REPORT':
            return {
                ...state,
                campaign_report: null
            };
        default:
            return state;
    }
}
