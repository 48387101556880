const initialState = {
    campaign: null,
    loading: false
};

export default function(state = initialState, action) {
    switch(action.type) {
        case 'CAMPAIGN_LOADING':
            return {
                ...state,
                loading: true
            };
        case 'GET_CAMPAIGN':
            return {
                ...state,
                campaign: action.payload,
                loading: false
            };
        case 'CLEAR_CAMPAIGN':
            return {
                ...state,
                campaign: null
            };
        default:
            return state;
    }
}
