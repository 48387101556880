import axios from 'axios';
import { GET_CAMPAIGN_REPORT_URL, GET_TOTAL_USER_REPORT_URL } from '../../api';

//Get Currernt Campaign
export const getCampaignReport = (id) => {
    return function(dispatch) {
        dispatch(setCampaignReportLoading(id));
        return axios.post(GET_CAMPAIGN_REPORT_URL, {id}).then((response) => {
            dispatch({
                type: 'GET_CAMPAIGN_REPORT',
                payload: response.data
            });
        }).catch((err) => {
            dispatch({
                type: 'GET_NO_CAMPAIGN_REPORT',
                payload: {}
            });
        });
    }
};

export const generateReport = (data) => {
    return function(dispatch) {
        dispatch({
            type: 'GENERATE_REPORT',
            payload: data
        });
    }
};

export const getTotalUser = () => {
    return function(dispatch) {
        return axios.get(GET_TOTAL_USER_REPORT_URL).then((response) => {
            dispatch({
                type: 'GET_TOTAL_USER',
                payload: response.data
            });
        }).catch((err) => {
            dispatch({
                type: 'GET_TOTAL_USER',
                payload: {}
            });
        });
    }
};

// Report Loading
export const setCampaignReportLoading = () => {
    return {
        type: 'CAMPAIGN_REPORT_LOADING'
    }
};

// Clear Report
export const clearCurrentCampaignReport = () => {
    return {
        type: 'CLEAR_CAMPAIGN_REPORT'
    }
};
