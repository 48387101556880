import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link } from 'react-router-dom';
import Header from "../layout/Header";
import {getCampaignAd} from '../../redux/actions/campaignAdActions';
import {encodeQueryParameter} from "../../utils/url";
import formatNumber from "../../utils/formatNumber";

const CampaignAd = (props) => {

    const id = props.match.params.id;
    const name = props.match.params.name;

    const { campaign_ad, loading } = useSelector((state) => state.campaign_ad);

    const dispatch = useDispatch();

    const [search, _search] = useState("");

    useEffect(() => {
        dispatch(getCampaignAd(id));
    }, []);

    const handleSearchFilter = (event) => {
        _search(event.target.value);
    };

    let campaignContent;

    if(campaign_ad === null || loading) {
        campaignContent =
            <div className="content content-fixed content-auth-alt">
                <div className="container d-flex justify-content-center ht-100p">
                    <div className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <p className="mg-t-10">Loading Campaign</p>
                    </div>
                </div>
            </div>
    } else {

        let renderAd = () => {
            const filtered_ad = campaign_ad.ads.filter((result) => {
                return result[0].name.toLowerCase().indexOf(search) !== -1;
            });

            return filtered_ad.map((result) => {
                return (
                    <div key={result[0].name} className="col-lg-4 col-md-6">
                        <div className="card card-body pd-t-8 pd-l-8 pd-b-8 mg-b-10 pd-b-4">
                            <a href={`#/ad-report/${encodeQueryParameter(result[0])}`}>
                                <div className="media">
                                    <img src={result[0].img_src} className="ht-120 wd-85 rounded mg-r-10" alt=""/>
                                    <div className="media-body">
                                        <h6 className="tx-uppercase tx-12 mg-t-5 tx-spacing-1 tx-color-02 tx-bold mg-b-8">{result[0].name}</h6>
                                        <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-8">
                                            <span className="tx-semibold">Status:</span> <span
                                            className="tx-success tx-semibold">{result[0].status}</span></h6>
                                        <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-8">
                                            <span className="tx-semibold">Daily Clicks:</span> {formatNumber(result[1])}</h6>
                                        <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-8">
                                            <span className="tx-semibold">Total Clicks:</span> {formatNumber(result[2])}</h6>
                                        <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-5">
                                            <span className="tx-semibold">Remain Clicks:</span> {formatNumber(result[0].total_clicks - result[2])}</h6>
                                        <h6 className="tx-uppercase tx-10 tx-spacing-1 tx-color-02 tx-normal mg-b-0">
                                            <span className="tx-semibold">Budget:</span> R{formatNumber(result[0].budget)}</h6>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                )
            });
        };

        campaignContent =
            <div className="content content-fixed">
                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-20 mg-xl-b-20">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Campaign</li>
                                </ol>
                            </nav>
                            <h4 className="mg-b-0 tx-spacing--1">{name}</h4>
                        </div>
                    </div>

                    <div className="mg-b-20">
                        <input type="search" className="form-control" value={search} onChange={handleSearchFilter.bind()} placeholder="Search Ad Name"/>
                    </div>

                    <div className="row row-xs">
                        {renderAd()}
                    </div>
                </div>
            </div>
    }

    return (
        <div>
            <Header />
            {campaignContent}
        </div>
    )
};

export default CampaignAd;
