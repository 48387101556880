const initialState = {};

export default function(state = initialState, action) {
    switch(action.type) {
        case 'GET_ERRORS':
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        default:
            return state;
    }
}