import React, { useEffect, useState } from 'react';
import Header from '../layout/Header';
import {useDispatch, useSelector, batch} from 'react-redux';
import {Link} from 'react-router-dom';
import CampaignList from "./CampaignList";
import {getCurrentCampaign, getProfile} from '../../redux/actions';
import $ from "jquery";

const Dashboard = () => {

    document.title = "Dashboard";
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const [campaign, _campaign] = useState([]);
    const [loading, _loading] = useState(true);

    useEffect(() => {
        getCurrentCampaign().then((result) => {
            if(result.error !== true) {
                _campaign(result.data);
                _loading(false);
            }
        });
        batch(() => {
            dispatch(getProfile());
        });
    }, []);

    let dashboardContent;

    if(campaign === null || loading) {
        dashboardContent =
            <div className="content content-fixed content-auth-alt">
                <div className="container d-flex justify-content-center ht-100p">
                    <div className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <p className="mg-t-10">Loading Campaign</p>
                    </div>
                </div>
            </div>
    } else {
        if(auth.user.address === "" || auth.user.contact_number === "") {
            dashboardContent =
                <div className="content content-fixed content-auth-alt">
                    <div className="container d-flex justify-content-center ht-100p">
                        <div
                            className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                            <div className="wd-80p wd-sm-300 mg-b-15 tx-center"><i className="wd-60 ht-60" data-feather="user"/></div>
                            <h4 className="tx-16 tx-sm-20">Profile Update</h4>
                            <p className="tx-color-03 mg-b-30 tx-center">Please update your profile before continue to use this service.</p>
                            <Link to="/account" className="btn btn-brand-02">Update Now</Link>
                        </div>
                    </div>
                </div>
        } else {
            //Check if user has campaign data
            if(Object.keys(campaign.data).length > 0) {
                dashboardContent = <div>
                    <div className="content content-fixed">
                        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                            <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                                <div>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                            <li className="breadcrumb-item">Dashboard</li>
                                        </ol>
                                    </nav>
                                    <h4 className="mg-b-0 tx-spacing--1">Welcome to Dashboard</h4>
                                </div>
                            </div>
                            <CampaignList campaign={campaign.data} />
                        </div>
                    </div>
                </div>
            } else {
                // User has no campaign
                const handleModal = () => {
                    $("#modalCreateAd").modal("show");
                };

                const handleCloseModal = () => {
                    $("#modalCreateAd").modal("hide");
                };

                dashboardContent = (
                    <div className="content content-fixed content-auth-alt">
                        <div className="container d-flex justify-content-center ht-100p">
                            <div
                                className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                                <div className="wd-80p wd-sm-300 mg-b-15 tx-center"><i className="wd-60 ht-60" data-feather="file"/></div>
                                <h4 className="tx-16 tx-sm-20">No Campaign</h4>
                                <p className="tx-color-03 mg-b-30 tx-center">You do not have any campaigns yet. Press
                                    create campaign below to start a new campaign.</p>
                                <button onClick={handleModal.bind()} className="btn btn-brand-02"><i data-feather="plus"/> Create Campaign</button>
                            </div>
                        </div>

                        <div className="modal fade" id="modalCreateAd" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                                <div className="modal-content">
                                    <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                        <div className="media align-items-center">
                                            <div className="media-body">
                                                <h4 className="tx-18 tx-sm-20 mg-b-10">Understand</h4>
                                                <p className="tx-13 tx-color-02 mg-b-15">You can have maximum five (5) adverts running under one "Campaign". Example...A campaign named "Groceries" can have eggs, bread, milk, butter and meat adverts running in the campaign. You can also set customized budgets to these adverts in the campaign. This allows you to manage each campaign with multiple adverts easily. To learn more about this, please visit our help center which is located under profile tab at the top right corner of the page and watch the tutorial "Creating ads under one campaign"</p>
                                                <Link to="/create-ads" onClick={handleCloseModal.bind()} className="btn btn-brand-02 btn-block">Close</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }

    return (
        <div>
            <Header />
            {dashboardContent}
        </div>
    )
};

export default Dashboard;
