import axios from 'axios';
import { GET_INVOICE_URL } from '../../api';

//Get Currernt Invoice
export const getCurrentInvoice = () => {
    return function(dispatch) {
        dispatch(setInvoiceLoading());
        return axios.get(GET_INVOICE_URL).then((response) => {
            dispatch({
                type: 'GET_INVOICE',
                payload: response.data
            })
        }).catch((err) => {
            dispatch({
                type: 'GET_NO_INVOICE',
                payload: {}
            })
        });
    }
};

export const setInvoice = (data) => {
    return function(dispatch) {
        dispatch({
            type: 'SET_INVOICE',
            payload: data
        });
    }
};

// Invoice Loading
export const setInvoiceLoading = () => {
    return {
        type: 'INVOICE_LOADING'
    }
};

// Clear Invoice
export const clearCurrentInvoice = () => {
    return {
        type: 'CLEAR_INVOICE'
    }
};
