import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch} from 'react-router-dom';
import Login from './components/auth/Login';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './redux/actions/authActions';
import { clearCurrentCampaign } from './redux/actions/campaignActions';
import Register from './components/auth/Register';
import Message from './components/verification/Message';
import VerifyLink from './components/verification/VerifyLink';
import ForgotPassword from './components/reset/ForgotPassword';
import VerifyForgotLink from './components/verification/VerifyPasswordLink';
import Private from "./components/Routes/Private";
import TermsConditions from "./components/pages/TermsConditions";
import { Provider } from 'react-redux';
import 'bootstrap';

import store from './redux/store';
import Try from "./components/pages/Try";
import Success from "./components/pages/Success";
import Failed from "./components/pages/Failed";

//Check for token
if(localStorage.jwtToken) {
    //Set auth token header auth
    setAuthToken(localStorage.jwtToken);

    //Decode token and get user info
    const decoded = jwt_decode(localStorage.jwtToken);

    //Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded));

    //Check for expired token
    const currentTime = Date.now() / 1000;
    if(decoded.exp < currentTime) {
        //Logout user
        store.dispatch(logoutUser());

        //Clear current campaign
        store.dispatch(clearCurrentCampaign());

        //Clear current profile
        window.location.href = "/";
    }
}

class App extends Component {
    render() {
        return (
            <Provider store={ store }>
                <Router>
                    <div className="App">
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route exact path="/register" component={Register} />
                            <Route exact path="/message" component={Message} />
                            <Route exact path="/verify-link/:token" component={VerifyLink} />
                            <Route exact path="/forgot-password" component={ForgotPassword} />
                            <Route exact path="/reset-password/:token" component={VerifyForgotLink} />
                            <Route exact path="/terms-conditions" component={TermsConditions} />
                            <Route exact path="/success" component={Success} />
                            <Route exact path="/failed" component={Failed} />
                            <Route exact path="/try" component={Try} />
                            <Route component={Private} />
                        </Switch>
                    </div>
                </Router>
            </Provider>
        );
    }
}

export default App;
