const initialState = {
    country: [
        {
            name : "South Africa",
            code : 27,
            exitCode : "0027",
            trunkPrefix : 0,
            nsn : 9,
            currency : "R",
            provinces : [
                {
                    name : "North West",
                    code : "NW"
                }
            ]
        }
    ]
};

export default function(state = initialState, action) {
    switch(action.type) {
        case 'COUNTRY':
            return {
                ...state,
                country: action.payload
            };
        default:
            return state;
    }
}
