import React, { useEffect } from 'react';
import Header from '../layout/Header';
import PropTypes from 'prop-types';
import {connect, useDispatch, useSelector} from 'react-redux';
import InvoiceList from "./InvoiceList";
import { getCurrentInvoice } from "../../redux/actions/invoiceActions";

const Invoice = () => {

    document.title = "Invoices";
    const dispatch = useDispatch();
    const { invoice, loading } = useSelector((state) => state.invoice);

    useEffect(() => {
        dispatch(getCurrentInvoice());
    }, []);

    let invoiceContent;

    if(invoice === null || loading) {
        invoiceContent =
            <div className="content content-fixed content-auth-alt">
                <div className="container d-flex justify-content-center ht-100p">
                    <div className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <p className="mg-t-10">Loading Campaign</p>
                    </div>
                </div>
            </div>
    } else {
        //Check if user has campaign data
        if(Object.keys(invoice.data).length > 0) {
            invoiceContent =
                <div className="content content-fixed">
                    <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                        <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                            <div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                        <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Invoice</li>
                                    </ol>
                                </nav>
                                <h4 className="mg-b-0 tx-spacing--1">Invoice</h4>
                            </div>
                        </div>
                        <InvoiceList invoice={invoice.data} />
                    </div>
                </div>
        } else {
            // User has no invoice
            invoiceContent = (
                <div className="content content-fixed content-auth-alt">
                    <div className="container d-flex justify-content-center ht-100p">
                        <div
                            className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                            <div className="wd-80p wd-sm-300 mg-b-15 tx-center"><i className="wd-60 ht-60" data-feather="file"/></div>
                            <h4 className="tx-16 tx-sm-20">No Invoice</h4>
                            <p className="tx-color-03 mg-b-30 tx-center">You do not have any invoice yet.</p>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div>
            <Header />
            {invoiceContent}
        </div>
    )
};

export default Invoice;
