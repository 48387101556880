import React, { useEffect } from 'react';
import {GET_REGIONAL_STATISTICS} from '../../../api';
import $ from "jquery";
import formatNumber from "../../../utils/formatNumber";

const Region = () => {
    const token = localStorage.getItem('jwtToken');

    useEffect(() => {
        $('#my_modal').on('show.bs.modal', function(e) {
            let raw = $(e.relatedTarget).data('data-id').split(",").map(String);

            $("#mag1").text(formatNumber(raw[1]));
            $("#mag2").text(formatNumber(raw[2]));
            $("#mag3").text(formatNumber(raw[3]));
            $("#mag4").text(formatNumber(raw[4]));

            $("#fag1").text(formatNumber(raw[5]));
            $("#fag2").text(formatNumber(raw[6]));
            $("#fag3").text(formatNumber(raw[7]));
            $("#fag4").text(formatNumber(raw[8]));

            $("#title").text(raw[0]);
        });

        $('#city_').DataTable({
            responsive: true,
            "searching": true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Provinces Report</p>'
            },
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_REGIONAL_STATISTICS, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success : function(res) {
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: res.data
                        });
                    }
                });
            },
            "columns": [
                {"data": "name"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return parseInt(data[1]) + parseInt(data[2]) + parseInt(data[3]) + parseInt(data[4]) + parseInt(data[5]) + parseInt(data[6]) + parseInt(data[7]) + parseInt(data[8]);
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return parseInt(data[1]) + parseInt(data[2]) + parseInt(data[3]) + parseInt(data[4]);
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return parseInt(data[5]) + parseInt(data[6]) + parseInt(data[7]) + parseInt(data[8]);
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return '<nav class="nav nav-icon-only"><a href="#/city-statistics/\'' +data[0]+ '\'" class="nav-link"><i class="ion-md-eye tx-18"/></i> City</a> <a href="#my_modal" data-toggle="modal" data-data-id=\'' +data+ '\' class="nav-link outline-none"><i class="ion-ios-people tx-18"/></i> Age</a></nav>'
                    },
                    "targets": 4
                }
            ]
        });

    }, []);

    return(
        <div data-label="Total Active Users" className="df-example demo-table mg-t-20">
            <table id="city_" className="table">
                <thead>
                <tr>
                    <th className="wd-20p">Provinces/State</th>
                    <th className="wd-15p">Total</th>
                    <th className="wd-15p">Male</th>
                    <th className="wd-15p">Female</th>
                    <td className="wd-15p">Actions</td>
                </tr>
                </thead>
                <div className="modal fade" id="my_modal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </a>
                                        <p className="tx-13 tx-color-02 mg-b-0 tx-semibold" id="title">Error</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                <table id="geography" className="table tx-13 mg-b-0">
                                    <thead>
                                    <tr>
                                        <th>Age Group</th>
                                        <th>Male</th>
                                        <th className="text-right">Female</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>18 - 24</td>
                                        <td id="mag1">0</td>
                                        <td id="fag1" className="text-right">0</td>
                                    </tr>
                                    <tr>
                                        <td>25 - 34</td>
                                        <td id="mag2">0</td>
                                        <td id="fag2" className="text-right">0</td>
                                    </tr>
                                    <tr>
                                        <td>35 - 44</td>
                                        <td id="mag3">0</td>
                                        <td id="fag3" className="text-right">0</td>
                                    </tr>
                                    <tr>
                                        <td>45 and above</td>
                                        <td id="mag4">0</td>
                                        <td id="fag4" className="text-right">0</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </table>
        </div>
    );
};

export default Region;
