import React, { useEffect, useState } from 'react';
import Header from '../layout/Header';
import {UPATE_PASSWORD_URL, UPATE_PROFILE_URL} from '../../api';
import axios from 'axios';
import {useSelector, useDispatch} from 'react-redux';
import $ from 'jquery';
import 'parsleyjs';
import { getProfile } from "../../redux/actions";
import Cleave from 'cleave.js/react.js';
import 'cleave.js/dist/addons/cleave-phone.i18n';
import cogoToast from "cogo-toast";

const Account = () => {

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth.user);
    const country = useSelector((state) => state.region.country);

    const [password, _password] = useState("");
    const [cpassword, _cpassword] = useState("");
    const [npassword, _npassword] = useState("");
    const [confirm_password, _confirm_password] = useState("");
    const [business_name, _business_name] = useState("");
    const [email, _email] = useState("");
    const [vat_number, _vat_number] = useState("");
    const [address, _address] = useState("");
    const [extra_address, _extra_address] = useState("");
    const [state, _state] = useState([]);
    const [provinces, _provinces] = useState([]);
    const [city, _city] = useState("");
    const [contact_number, _contact_number] = useState("");
    const [person_name, _person_name] = useState("");
    const [postal_code, _postal_code] = useState("");
    const [country_code, _country_code] = useState("ZA");
    const [country_nsn, _country_nsn] = useState("9");
    const [stateReady, setStateReady] = useState(false);

    useEffect(() => {
        document.title = "Account";
        $(function () {
            $('#_account').parsley();
            $('#_change_password').parsley();
        });

        _business_name(auth.business_name);
        _email(auth.email);
        _vat_number(auth.vat_number);
        _address(auth.address);
        _extra_address(auth.extra_address);
        _state(auth.state);
        _city(auth.city);
        _contact_number(auth.contact_number);
        _person_name(auth.person_name);
        _postal_code(auth.postal_code);

        let {nsn, shortCode, provinces} = country.find((result) => {
            return result.name === auth.country;
        });

        _provinces(provinces);
        _country_nsn(nsn);
        _country_code(shortCode);

        return () => {
            $('#_account').parsley().destroy();
            $('#_change_password').parsley().destroy();
        }
    }, []);

    const handleUpdatePassword = (event) => {
        event.preventDefault();
        if(npassword.length > 5 && cpassword.length > 5 && confirm_password.length > 5 && npassword === confirm_password) {
            const option = {
                hideAfter: 5
            };

            setStateReady(true);
            axios.post(UPATE_PASSWORD_URL, {npassword, cpassword}).then((response) => {
                setStateReady(false);

                _cpassword("");
                _npassword("");
                _confirm_password("");

                $('#_account').parsley().reset();

                cogoToast.success(response.data.message, option);
            }).catch((err) => {
                if (err.response.status === 422) {
                    setStateReady(false);

                    $('#_account').parsley().reset();
                    cogoToast.error(err.response.data['error'], option);
                } else {
                    cogoToast.error(err, option);
                }
            });
        } else {
            return null;
        }
    };

    const handleUpdateProfile = (e) => {
        e.preventDefault();
        if(
            email.length > 5
            && address.length > 3
            && city.length > 3
            && state.length > 1
            && password.length > 5
            && contact_number.length > 6
            && person_name.length > 2
        ) {
            const option = {
                hideAfter: 5
            };

            setStateReady(true);

            axios.post(UPATE_PROFILE_URL, {email, vat_number, password, address, extra_address, state, city, contact_number, person_name, postal_code}).then((response) => {
                dispatch(getProfile());
                setStateReady(false);
                _password("");
                cogoToast.success(response.data.message, option);
            }).catch((err) => {
                if (err.response.status === 422) {
                    setStateReady(false);
                    _password("");

                    cogoToast.error(err.response.data['error'], option);
                } else {
                    cogoToast.error(err, option);
                }
            });
        } else {
            return null;
        }

    };

    const handleCurrentPasswordChange = (event) => {
        _cpassword(event.target.value);
    };

    const handleNewPasswordChange = (event) => {
        _npassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        _confirm_password(event.target.value);
    };

    const handleVatChange = (event) => {
        _vat_number(event.target.value);
    };

    const handleEmailChange = (event) => {
        _email(event.target.value);
    };

    const handlePersonNameChange = (event) => {
        _person_name(event.target.value);
    };

    const handleContactChange = (event) => {
        _contact_number(event.target.value);
    };

    const handleAddressChange = (event) => {
        _address(event.target.value);
    };

    const handleExtraAddressChange = (event) => {
        _extra_address(event.target.value);
    };

    const handleStateSelected = (event) => {
        _state(event.target.value);
    };

    const handleCityChange = (event) => {
        _city(event.target.value);
    };

    const handlePostalCodeChange = (event) => {
        _postal_code(event.target.value);
    };

    const handlePasswordChange = (event) => {
        _password(event.target.value);
    };

    let renderProfileButton = () => {
        if(stateReady === true) {
            return(
                <button disabled className="btn btn-brand-02 btn-block">
                    <span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>
                    Please Wait</button>
            )
        } else {
            return(
                <button className="btn btn-brand-02 btn-block">Update Profile</button>
            );
        }
    };

    let renderButton = () => {
        if(stateReady === true) {
            return(
                <button disabled className="btn btn-brand-02 btn-block">
                    <span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>
                    Please Wait</button>
            )
        } else {
            return(
                <button className="btn btn-brand-02 btn-block">Update Password</button>
            );
        }
    };

    return (
        <div>
            <Header />
            <div className="content content-fixed">
                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Account</li>
                                </ol>
                            </nav>
                            <h4 className="mg-b-0 tx-spacing--1">Your Profile</h4>
                        </div>
                    </div>
                    <div className="card card-body pd-x-25 pd-sm-x-30 pd-t-15 pd-sm-t-20 pd-b-15 pd-sm-b-20">
                        <div className="nav-wrapper mg-b-20 tx-13">
                            <div>
                                <nav className="nav nav-line tx-medium">
                                    <a href="#edit_profile" className="nav-link active" data-toggle="tab">Edit Profile</a>
                                    <a href="#change_password" className="nav-link" data-toggle="tab">Change Password</a>
                                </nav>
                            </div>
                        </div>

                        <div className="tab-content">
                            <div id="edit_profile" className="tab-pane fade active show">
                                <form id="_account" className="parsley-style-1" onSubmit={handleUpdateProfile.bind()}>
                                    <div className="form-group">
                                        <label>Business Name</label>
                                        <input type="text" value={business_name} className="form-control" disabled/>
                                    </div>
                                    <div className="form-group">
                                        <label>VAT Number(Optional)</label>
                                        <input type="text" className="form-control"
                                               placeholder="Enter VAT Number"
                                               value={vat_number}
                                               onChange={handleVatChange.bind()}
                                        />
                                    </div>
                                    <div id="personWrapper" className="form-group parsley-input">
                                        <label>Account Manager<span className="tx-danger">*</span></label>
                                        <input type="text" className="form-control"
                                               value={person_name}
                                               onChange={handlePersonNameChange.bind()}
                                               placeholder="Enter Person Name"
                                               data-parsley-class-handler="#personWrapper" required/>
                                    </div>
                                    <div id="streetWrapper" className="form-group parsley-input">
                                        <label>Address 1<span className="tx-danger">*</span></label>
                                        <input type="text" className="form-control"
                                               value={address}
                                               onChange={handleAddressChange.bind()}
                                               placeholder="Street Address"
                                               data-parsley-class-handler="#streetWrapper" required/>
                                    </div>
                                    <div id="streetWrapper" className="form-group parsley-input">
                                        <label>Address 2 (Optional)</label>
                                        <input type="text" className="form-control" placeholder="Address 2"
                                               value={extra_address}
                                               onChange={handleExtraAddressChange.bind()}
                                               autoComplete="none"
                                        />
                                    </div>
                                    <div className="form-group" data-parsley-validate="true">
                                        <label>Province/State<span className="tx-danger">*</span></label>
                                        <select className="custom-select"
                                                value={state}
                                                onChange={handleStateSelected.bind()}
                                                required>
                                            <option value="" disabled>Select State</option>
                                            {provinces.map((result) => <option key={result.name} value={result.name}>{result.name}</option>)}
                                        </select>
                                    </div>
                                    <div id="cityWrapper" className="form-group parsley-input">
                                        <label>City<span className="tx-danger">*</span></label>
                                        <input type="text" className="form-control"
                                               value={city}
                                               onChange={handleCityChange.bind()}
                                               placeholder="Enter City"
                                               data-parsley-class-handler="#cityWrapper" required/>
                                    </div>
                                    <div id="postalWrapper" className="form-group">
                                        <label>Postal Code</label>
                                        <input type="number" className="form-control"
                                               value={postal_code}
                                               onChange={handlePostalCodeChange.bind()}
                                               placeholder="Enter Postal Code"
                                        />
                                    </div>
                                    <div id="emailWrapper" className="form-group parsley-input">
                                        <label>Email Address<span className="tx-danger">*</span></label>
                                        <input type="email" className="form-control"
                                               value={email}
                                               onChange={handleEmailChange.bind()}
                                               placeholder="Enter valid email address"
                                               data-parsley-class-handler="#emailWrapper" required/>
                                    </div>
                                    <div id="contactWrapper" className="form-group parsley-input">
                                        <label>Contact Number<span className="tx-danger">*</span></label>
                                        <Cleave type="tel" className="form-control"
                                                options={{
                                                    phone: true,
                                                    phoneRegionCode: country_code
                                                }}
                                                minLength={country_nsn}
                                                value={contact_number}
                                                onChange={handleContactChange.bind()}
                                                placeholder="Enter Contact Number"
                                                data-parsley-class-handler="#contactWrapper" required/>
                                    </div>
                                    <div id="passwordWrapper" className="form-group parsley-input">
                                        <label>Current Password<span className="tx-danger">*</span></label>
                                        <input type="password" className="form-control"
                                               value={password}
                                               onChange={handlePasswordChange.bind()}
                                               placeholder="Enter Current Password"
                                               data-parsley-class-handler="#passwordWrapper" required/>
                                    </div>

                                    {renderProfileButton()}
                                </form>
                            </div>
                            <div id="change_password" className="tab-pane fade">
                                <form id="_change_password" className="parsley-style-1" onSubmit={handleUpdatePassword.bind()}>
                                    <div id="cPasswordWrapper" className="form-group parsley-input">
                                        <label>Current Password<span className="tx-danger">*</span></label>
                                        <input type="password" className="form-control"
                                               placeholder="Enter current password"
                                               data-parsley-minlength="6"
                                               value={cpassword}
                                               onChange={handleCurrentPasswordChange.bind()}
                                               data-parsley-class-handler="#cPasswordWrapper" required/>
                                    </div>

                                    <div id="nPasswordWrapper" className="form-group parsley-input">
                                        <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">New Password<span className="tx-danger">*</span></label>
                                        <input id="pass" type="password" className="form-control"
                                               placeholder="Enter new password"
                                               data-parsley-minlength="6"
                                               value={npassword}
                                               onChange={handleNewPasswordChange.bind()}
                                               data-parsley-class-handler="#nPasswordWrapper" required/>
                                    </div>

                                    <div id="ccPasswordWrapper" className="form-group parsley-input">
                                        <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Confirm Password<span className="tx-danger">*</span></label>
                                        <input type="password" className="form-control"
                                               placeholder="Enter confirm password"
                                               data-parsley-minlength="6"
                                               data-parsley-equalto='#pass'
                                               value={confirm_password}
                                               onChange={handleConfirmPasswordChange.bind()}
                                               data-parsley-class-handler="#ccPasswordWrapper" required/>
                                    </div>

                                    {renderButton()}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Account;
