import React, { useEffect } from 'react';
import formatNumber from "../../../utils/formatNumber";
import {Link} from 'react-router-dom';
import 'datatables.net';
import 'datatables.net-responsive';
import $ from "jquery";
import persistStorage from "../../../utils/persistStorage";
import {routeCity} from "../../../redux/actions/routeActions";
import {useDispatch} from "react-redux";
import {encodeQueryParameter} from "../../../utils/url";

const Geography = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        $('#region').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search...',
                sSearch: '',
                lengthMenu: '_MENU_ items/page'
            }
        });
    }, []);

    let renderRegion = () => {

        return props.ad_report.map((result, index) => {
            let name = Object.getOwnPropertyNames(result);
            let total_clicks = result[name][0]+result[name][1]+result[name][2]+result[name][3]+result[name][4]+result[name][5]+result[name][6]+result[name][7];
            let total_male = result[name][0]+result[name][1]+result[name][2]+result[name][3];
            let total_female = result[name][4]+result[name][5]+result[name][6]+result[name][7];
            let renderRate = (initial, max) => {
                return initial/max * 100;
            };
            let id = props.ad_data._id;
            let raw = {
                start_date: props.ad_data.start_date,
                end_date: props.ad_data.end_date
            };

            return(
                <tr key={name}>
                    <td>{name}</td>
                    <td>{formatNumber(total_clicks)}</td>
                    <td>{renderRate(total_clicks, props.total_clicks).toFixed(2)+"%"}</td>
                    <td>{formatNumber(total_male)}</td>
                    <td>{formatNumber(total_female)}</td>
                    <td>
                        <nav className="nav nav-icon-only">
                            {/*<Link to={`/ad-city/${id}/${name}/${encodeQueryParameter(raw)}`} className="nav-link"><i className="fa fa-eye"/> City</Link>*/}
                            <a href="#" data-toggle="modal" data-target={"#_"+index} className="nav-link outline-none"><i className="fa fa-venus-mars"></i> Age</a></nav>
                        <div className="modal fade" id={"_"+index} tabIndex="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div className="media align-items-center">
                                            <div className="media-body">
                                                <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </a>
                                                <p className="tx-13 tx-color-02 mg-b-0 tx-semibold">{name} - Age Group</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-body">
                                        <table id="geography" className="table tx-13 mg-b-0">
                                            <thead>
                                            <tr>
                                                <th>Age Group</th>
                                                <th>Male</th>
                                                <th className="text-right">Female</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>18 - 24</td>
                                                <td>{formatNumber(result[name][0])}</td>
                                                <td className="text-right">{formatNumber(result[name][4])}</td>
                                            </tr>
                                            <tr>
                                                <td>25 - 34</td>
                                                <td>{formatNumber(result[name][1])}</td>
                                                <td className="text-right">{formatNumber(result[name][5])}</td>
                                            </tr>
                                            <tr>
                                                <td>35 - 44</td>
                                                <td>{formatNumber(result[name][2])}</td>
                                                <td className="text-right">{formatNumber(result[name][6])}</td>
                                            </tr>
                                            <tr>
                                                <td>45 and above</td>
                                                <td>{formatNumber(result[name][3])}</td>
                                                <td className="text-right">{formatNumber(result[name][7])}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        });
    };
    return(
        <div data-label="Campaign Clicks by Geographic Targeting" className="df-example demo-table mg-t-20">
            <div className="table-responsive">
                <table id="region" className="table">
                    <thead>
                    <tr>
                        <th className="wd-20p">Region</th>
                        <th className="wd-15p">Clicks</th>
                        <th className="wd-10p">Hit Rate</th>
                        <th className="wd-15p">Male</th>
                        <th className="wd-15p">Female</th>
                        <td className="wd-15p">Actions</td>
                    </tr>
                    </thead>
                    <tbody>
                    {renderRegion()}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Geography;
